import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { getText } from "../../../i18n/LocalizedText";
import { type ProductTableRecord } from "../../../product/ProductOverviewTable/ProductOverviewTable";
import ProductWidgetItem from "../../../product/ProductOverviewTable/ProductWidgetItem";
import { getWidgetFieldKey } from "../../../widgets/WidgetField/utils/WidgetFieldUtils";
import { type WidgetField } from "../../../widgets/WidgetField/WidgetField";
import { type StrictColumnsType } from "../types/StrictColumnsType";

interface UseWidgetColumnsProps {
  fields: WidgetField[];
  enableStickyColumns?: boolean;
  minWidth?: number;
  width?: number;
  cellClassName?: string;
}

export default function useWidgetColumns<T extends ProductTableRecord>({
  fields,
  enableStickyColumns,
  minWidth,
  width,
  cellClassName
}: UseWidgetColumnsProps): StrictColumnsType<T> {
  const renderWidgetItem = useCallback((field: WidgetField, record: T) => {
    return <ProductWidgetItem product={record.product} field={field} />;
  }, []);

  const getStickyAndDimensionsSettings = useCallback(
    (isFirstColumn: boolean) => {
      if (!enableStickyColumns || !isFirstColumn) {
        return {
          minWidth: minWidth,
          width: width
        };
      }
      return {
        fixed: "left" as const,
        minWidth: 300,
        width: 300
      };
    },
    [enableStickyColumns, minWidth, width]
  );

  return useMemo(() => {
    return fields.map((field, index) => {
      const key = getWidgetFieldKey(field);
      const isFirstColumn = index === 0;

      const cellClasses = classNames(
        "ant-table-cell--widget",
        {
          "ant-table-cell--widget--fixed": isFirstColumn && enableStickyColumns
        },
        cellClassName
      );

      const stickyColumnSettings =
        getStickyAndDimensionsSettings(isFirstColumn);

      return {
        key: key,
        title: getText(field.labels, field.name),
        className: cellClasses,
        render: (_: any, record: T) => renderWidgetItem(field, record),
        ...stickyColumnSettings
      };
    });
  }, [
    fields,
    enableStickyColumns,
    cellClassName,
    getStickyAndDimensionsSettings,
    renderWidgetItem
  ]);
}
