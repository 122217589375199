import { isEmpty } from "lodash";
import { InfoTooltip } from "../../../components/InfoTooltip/InfoTooltip";
import { getText } from "../../../i18n/LocalizedText";
import { type ProductData } from "../../../types/canonical/ProductData";
import type Subject from "../../../types/canonical/Subject";
import { WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { getProductDataSubjects } from "../../WidgetPanel/MatrixWidget/Subjects/subjectUtils";
import { FieldSection } from "./FieldSection";

interface SubjectsItemProps {
  field: WidgetField;
  type: WidgetType;
  data: ProductData;
  hideHeader?: boolean;
  isTitle: boolean;
}

export function SubjectsItem({
  field,
  type,
  data,
  hideHeader,
  isTitle
}: Readonly<SubjectsItemProps>) {
  let subjects = getProductDataSubjects(data);

  const subjectType = field.referenceType;
  if (subjectType) {
    subjects = subjects.filter(
      (subject) => subject.reference.type === subjectType
    );
  }

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(subjects)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <ul className={type === WidgetType.SIDEBAR ? "list-unstyled" : ""}>
          {subjects?.map((subject, index) => (
            <li key={index}>
              <SubjectItem subject={subject} />
            </li>
          ))}
        </ul>
      )}
    </FieldSection>
  );
}

type SubjectItemProps = {
  subject: Subject;
};

function SubjectItem({ subject }: Readonly<SubjectItemProps>) {
  const description = getText(subject.descriptions);

  return (
    <>
      <span>{getText(subject.reference.names)}</span>
      <InfoTooltip tooltip={description} />
    </>
  );
}
