import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../components";
import Link from "../../types/Link";
import { type Product } from "../../types/Product";
import { type Widget } from "../types/Widget";
import { WidgetType } from "../types/WidgetType";
import { AppraisalsWidget } from "./AppraisalsWidget/AppraisalsWidget";
import { ContainerWidget } from "./ContainerWidget/ContainerWidget";
import { MatrixWidget } from "./MatrixWidget/MatrixWidget";
import { PrimaryWidget } from "./PrimaryWidget/PrimaryWidget";
import { SidebarWidget } from "./SidebarWidget/SidebarWidget";
import { StructureWidget } from "./StructureWidget/StructureWidget";

type Props = {
  widget: Widget;
  product?: Product;
  expanded: boolean;
  expandable?: boolean;
};

async function getEntities(product: Product | undefined, entityType: string) {
  if (!product || product.productType === entityType) {
    return Promise.resolve(product ? [product] : []);
  }

  return Link.getRelated(product, entityType);
}

export function WidgetPanel({
  widget,
  product,
  expanded,
  expandable = true
}: Readonly<Props>) {
  const yearId = product?.data.year.id;
  const productId = product?.id;

  const state = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["widget-entity", productId, yearId, widget.entityType],
    queryFn: () => getEntities(product, widget.entityType)
  });

  return (
    <Loader state={state}>
      {(entities) => (
        <>
          {entities.map((entity) => (
            <WidgetRender
              key={`entity-${entity.id}`}
              widget={widget}
              product={entity}
              expanded={expanded}
              expandable={expandable}
            />
          ))}
        </>
      )}
    </Loader>
  );
}

export function WidgetRender({
  widget,
  product,
  expanded,
  expandable = true
}: Readonly<Props>) {
  if (!product || !widget) {
    return <></>;
  }

  switch (widget.type) {
    case WidgetType.APPRAISALS:
      return <AppraisalsWidget widget={widget} product={product} />;
    case WidgetType.CONTAINER:
      return (
        <ContainerWidget
          widget={widget}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      );
    case WidgetType.MATRIX:
      return <MatrixWidget widget={widget} product={product} />;
    case WidgetType.PRIMARY:
      return <PrimaryWidget widget={widget} product={product} />;
    case WidgetType.SIDEBAR:
      return (
        <SidebarWidget
          widget={widget}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      );
    case WidgetType.STRUCTURE:
      return (
        <StructureWidget
          widget={widget}
          product={product}
          expanded={expanded}
        />
      );
    default:
      return <></>;
  }
}
