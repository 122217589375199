import { memo } from "react";
import { type ParsedFilter } from "../../../../filters/hooks/useParseFilter";
import type Subject from "../../../../types/canonical/Subject";
import { type Product } from "../../../../types/Product";
import SubjectValues from "../Subjects/SubjectsValues/SubjectValues";
import { getProductSubjects } from "../Subjects/subjectUtils";

interface SubjectCellProps {
  widgetFilter: ParsedFilter;
  subject: Subject;
  product?: Product;
}

const MatrixWidgetTableSubjectCell = memo(
  ({ widgetFilter, subject, product }: SubjectCellProps) => {
    const productCellSubjects = getProductSubjects(widgetFilter, product);
    const matchingSubjects = productCellSubjects.filter(
      (cellSubject) => cellSubject.reference.id === subject.reference.id
    );

    return (
      <div className="matrix-widget__cell--subject__contents">
        <SubjectValues subjects={matchingSubjects} />
      </div>
    );
  }
);

MatrixWidgetTableSubjectCell.displayName = "MatrixWidgetTableSubjectCell";

export default MatrixWidgetTableSubjectCell;
