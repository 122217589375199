import { type Key } from "react";
import { capitalize, isEmpty } from "lodash";
import { getText } from "../../../i18n/LocalizedText";
import type Reference from "../../../references/Reference";
import { displayName } from "../../../references/Reference";
import { type WidgetField } from "../WidgetField";

const getWidgetFieldKey = (field: WidgetField): Key => {
  return `field-${field.id}`;
};

const getWidgetFieldLabel = (field: WidgetField) => {
  return getText(field.labels, capitalize(field.name));
};

const widgetFieldHasFormat = (field: WidgetField) => {
  return field.format && !isEmpty(field.format);
};

const translateWidgetFields = (values: any[], references: Reference[]) => {
  return values
    .map((value) => {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }

      return displayName(value, references, value);
    })
    .sort()
    .join(", ");
};

export {
  getWidgetFieldKey,
  getWidgetFieldLabel,
  widgetFieldHasFormat,
  translateWidgetFields
};
