import { type AuthenticationState, getService } from "@42.nl/authentication";
import { useQuery } from "@tanstack/react-query";
import { getAuthenticationService } from "./AuthenticationService";
import { type CurrentUser } from "./CurrentUser";

export function useAuthentication(): AuthenticationState<CurrentUser> {
  const { data } = useQuery({
    queryKey: ["auth"],
    queryFn: async () => await getAuthenticationService().getAuthentication()
  });

  if (!data) {
    return getService<CurrentUser>().getState();
  }

  return data;
}
