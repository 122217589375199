import { isEmpty } from "lodash";
import { matchesRegex } from "../../../core/validation/validation";
import { FILTER_REGEX } from "../../filters/Filters";
import type Tab from "../types/Tab";

export function tabHasFilter(tab: Tab): boolean {
  const tabFilter = tab.filter;
  if (!tabFilter) return false;

  return !isEmpty(tabFilter) && FILTER_REGEX.test(tabFilter);
}

export function tabModesValidator(name: string, example: string) {
  const TAB_MODES_REGEX = /^(list|table)(,(list|table))?$/;

  return matchesRegex(TAB_MODES_REGEX, name, example);
}
