import { DownOutlined, SwitcherOutlined } from "@ant-design/icons";
import { Dropdown, Button, Space, type MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import ProductTypeIcon from "../../../../../../components/Icon/product/productType/ProductTypeIcon.component";
import { ProductTreeNodeTypeEnum } from "../../ProductTreeStore/types/ProductTreeNodeType";
import ProductTreeBulkToggleDropdownItem from "./ProductTreeBulkToggleDropdownItem";
import { type ProductTreeBulkToggleMenuConfigItem } from "./types/ProductTreeBulkToggleMenuConfig";
import { ProductTreeBulkToggleMode } from "./types/ProductTreeBulkToggleMode";

const menuConfig: ProductTreeBulkToggleMenuConfigItem[] = [
  {
    key: "groups",
    icon: (
      <ProductTypeIcon icon={ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP} />
    ),
    labelKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.GROUP.TITLE",
    expandKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.GROUP.EXPAND",
    collapseKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.GROUP.COLLAPSE",
    expandAction: ProductTreeBulkToggleMode.EXPAND_GROUPS,
    collapseAction: ProductTreeBulkToggleMode.COLLAPSE_GROUPS
  },
  {
    key: "modules",
    icon: <ProductTypeIcon icon={ProductTreeNodeTypeEnum.GROUP_TO_MODULE} />,
    labelKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.MODULE.TITLE",
    expandKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.MODULE.EXPAND",
    collapseKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.MODULE.COLLAPSE",
    expandAction: ProductTreeBulkToggleMode.EXPAND_MODULES,
    collapseAction: ProductTreeBulkToggleMode.COLLAPSE_MODULES,
    divideAfter: true
  },
  {
    key: "all",
    icon: <SwitcherOutlined />,
    labelKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.ALL.TITLE",
    expandKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.ALL.EXPAND",
    collapseKey: "widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.ALL.COLLAPSE",
    expandAction: ProductTreeBulkToggleMode.EXPAND_ALL,
    collapseAction: ProductTreeBulkToggleMode.COLLAPSE_ALL
  }
];

const ProductTreeBulkToggleDropdown = ({
  onAction
}: {
  onAction: (action: ProductTreeBulkToggleMode) => void;
}) => {
  const { t } = useTranslation(["widget"]);

  const menuItems: MenuProps["items"] = menuConfig.flatMap((config, index) => {
    const items: MenuProps["items"] = [
      {
        key: config.key,
        label: (
          <ProductTreeBulkToggleDropdownItem
            key={"item-" + config.key}
            config={config}
            onAction={onAction}
          />
        )
      }
    ];

    if (config.divideAfter) {
      items.push({ type: "divider", key: `divider-${index}` });
    }

    return items;
  });

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button>
        <Space>
          {t("widget:TYPE.MATRIX.ACTIONS.TOGGLE.BULK.TITLE")}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ProductTreeBulkToggleDropdown;
