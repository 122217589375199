import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Navbar, Row } from "reactstrap";
import background from "../../../../src/images/background.jpeg";
import EnvBanner from "../../components/EnvBanner/EnvBanner";
import LanguageSwitcher from "../../i18n/LanguageSwitcher/LanguageSwitcher";
import { toLogin } from "../Login/links";

type Props = {
  /**
   * Whether to show the back to login link.
   *
   * Defaults to true.
   */
  showBackToLogin?: boolean;

  /**
   * The content to render inside the SimpleAppFrame.
   */
  children: React.ReactNode;
};

/**
 * Version of the AppFrame to show before the user has logged in.
 */
export default function SimpleAppFrame(props: Readonly<Props>) {
  const { children, showBackToLogin = true } = props;
  const { t } = useTranslation("login");

  return (
    <Container
      fluid
      className="SimpleAppFrame"
      style={{
        background: `rgba(0, 0, 0, 0.65) url(${background}) center center`
      }}
    >
      <EnvBanner />

      <Row className="justify-content-center">
        <Col md={6} lg={4} xs={12} className="pt-5 mt-5">
          <Card className="border-0">
            <CardBody className="text-white py-2 border-0 ease-in">
              <Navbar color="primary" className="p-0 navbar-dark" expand>
                {showBackToLogin && (
                  <Link
                    className="float-start text-white"
                    style={{ fontSize: "1.5rem" }}
                    to={toLogin()}
                  >
                    {t("LOGIN.BACK_TO_LOGIN")}
                  </Link>
                )}
                <div className="d-flex flex-row align-self-center">
                  <LanguageSwitcher />
                </div>
              </Navbar>
            </CardBody>
            {children}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
