import i18n from "../../core/i18n";

type Named = {
  code?: string;
  localName?: string;
  englishName?: string;
};

export function getName(entity?: Named) {
  if (!entity) {
    return null;
  }

  const name =
    i18n.language === "nl"
      ? (entity.localName ?? entity.englishName)
      : (entity.englishName ?? entity.localName);
  return name ?? entity.code;
}
