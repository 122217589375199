import { PaperClipOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const ModuleIcon = () => {
  const { t } = useTranslation(["widget"]);
  const label = t("widget:TYPE.MATRIX.TABLE.MODULE.TITLE");

  return (
    <div aria-label={label}>
      <Tooltip title={label}>
        <PaperClipOutlined aria-hidden={true} />
      </Tooltip>
    </div>
  );
};

export default ModuleIcon;
