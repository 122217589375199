import { isEmpty } from "lodash";
import { ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import type Offering from "../../../types/canonical/Offering";
import { getPeriodsDisplayName } from "../../../types/canonical/Offering";
import { type WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  offerings: Offering[] | undefined;
  year: string;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function PeriodsItem({
  field,
  type,
  offerings,
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references: blocks } = useReferences(ReferenceType.TIME_BLOCK, year);

  const periods = getPeriodsDisplayName(offerings, blocks);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(periods)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() =>
        !periods?.length ? (
          <></>
        ) : (
          <>
            {periods.map((period, index) => (
              <div key={`period-${index}`}>{period}</div>
            ))}
          </>
        )
      }
    </FieldSection>
  );
}
