import { useMemo } from "react";
import { useWidgetGroups } from "../../../../hooks/useWidgetGroups.hook";
import { type Widget } from "../../../../types/Widget";
import { getVisibleSortedWidgetFields } from "../../../../utils/WidgetUtils";
import { type WidgetField } from "../../../../WidgetField/WidgetField";

export function useMatrixWidgetFields(widgets: Widget[]): WidgetField[] {
  const groupedWidgets = useWidgetGroups(widgets);

  return useMemo(() => {
    return groupedWidgets.matrix.flatMap((widget) =>
      getVisibleSortedWidgetFields(widget)
    );
  }, [groupedWidgets.matrix]);
}
