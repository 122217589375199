import { type WidgetRenderVariantProps } from "../../types/WidgetRenderVariantProps";
import MatrixWidgetTable from "../MatrixWidgetTable/MatrixWidgetTable";
import ProductTreeBulkToggleDropdown from "../ProductTree/ProductTreeActions/ProductTreeBulkToggle/ProductTreeBulkToggleDropdown";
import { type ProductTreeBulkToggleMode } from "../ProductTree/ProductTreeActions/ProductTreeBulkToggle/types/ProductTreeBulkToggleMode";
import { useProductTreeData } from "../ProductTree/ProductTreeStore/hooks/useProductTreeData.hook";
import { useProductTreeStore } from "../ProductTree/ProductTreeStore/hooks/useProductTreeStore.hook";

export function MatrixWidgetContainer({
  widget,
  product
}: Readonly<WidgetRenderVariantProps>) {
  const { onBulkToggleAction } = useProductTreeStore();
  const { onNodeToggle } = useProductTreeData(product.data.year.id);

  function onAction(action: ProductTreeBulkToggleMode) {
    onBulkToggleAction(action, onNodeToggle);
  }

  return (
    <div className="d-flex flex-column gap-4 matrix-widget">
      <div className="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap gap--0_4rem">
        <ProductTreeBulkToggleDropdown onAction={onAction} />
      </div>

      <MatrixWidgetTable widget={widget} product={product} />
    </div>
  );
}
