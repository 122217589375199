import { useTranslation } from "react-i18next";
import { Alert, Row } from "reactstrap";
import AppFrame from "../components/AppFrame/AppFrame";

export const NO_YEARS_APP_URL = "/no-years";

export default function NoYearsApp() {
  const { t } = useTranslation("year");

  return (
    <AppFrame title={t("NO_YEARS")}>
      <Row className="justify-content-center">
        <Alert color="info">{t("NO_YEARS_MESSAGE")}</Alert>
      </Row>
    </AppFrame>
  );
}
