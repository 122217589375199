import { type Key } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { loadModuleRows } from "../../../../../../groups/GroupContents/hooks/useGroupModules.hook";
import { getPeriodService } from "../../../../../../periods/PeriodService";
import { ReferenceType } from "../../../../../../references/Reference";
import { useReferences } from "../../../../../../references/useReferences";
import { type Group } from "../../../../../../types/canonical/Group";
import SimpleGroup from "../../../../../../types/SimpleGroup";
import {
  getObjectives,
  type ModuleRow,
  sortModules
} from "../../../../../../types/SimpleModule";
import { type ProductTreeNode } from "../types/ProductTreeNode";
import { ProductTreeNodeTypeEnum } from "../types/ProductTreeNodeType";
import findProductNodeRecursively from "../utils/findProductNodeRecursively";
import { getProductTreeNodeCompositeUid } from "../utils/getProductTreeNodeCompositeUid";
import { useProductTreeStore } from "./useProductTreeStore.hook";

export const useProductTreeData = (yearExternalId: string) => {
  const queryClient = useQueryClient();
  const { updateProductTreeNode, productTree } = useProductTreeStore();

  const periods = getPeriodService().getPeriods(yearExternalId);
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    yearExternalId
  );
  const { references: phases } = useReferences(
    ReferenceType.PHASE,
    yearExternalId
  );

  const fetchGroupModules = async (
    group: ProductTreeNode
  ): Promise<ProductTreeNode[]> => {
    const groupData = group.product?.data as Group;
    if (!groupData) return [];

    const queryKey = SimpleGroup.getQueryKey(groupData.uid, groupData.year.id);
    const moduleRows = await queryClient.fetchQuery({
      queryKey,
      queryFn: () => loadModuleRows(groupData)
    });

    const sortedModules: ModuleRow[] = sortModules(
      moduleRows,
      phases,
      periods,
      timeBlocks
    );

    return sortedModules.map(
      (moduleRow: ModuleRow): ProductTreeNode => ({
        uid: moduleRow.module.data.uid,
        key: getProductTreeNodeCompositeUid(
          group.key,
          moduleRow.module.data.uid
        ),
        type: ProductTreeNodeTypeEnum.GROUP_TO_MODULE,
        structure: moduleRow.structure,
        product: moduleRow.module,
        isFetched: true,
        children: getObjectives(moduleRow)
      })
    );
  };

  const onNodeToggle = async (compositeUid: Key) => {
    const groupNode = findProductNodeRecursively(productTree, compositeUid);

    if (!groupNode || groupNode.isFetched) {
      return;
    }

    const groupChildren = (groupNode.children || []).filter(
      (child) => child.type === ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP
    );

    const fetchedModules = await fetchGroupModules(groupNode);

    updateProductTreeNode(groupNode.key, {
      ...groupNode,
      children: [...groupChildren, ...fetchedModules],
      isFetched: true
    });
  };

  return { onNodeToggle, fetchGroupModules };
};
