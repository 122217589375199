import { get, makeResource, post } from "@42.nl/spring-connect";
import { type QueryParams, search } from "../filters/Search";
import type AcademicYear from "./AcademicYear";
import type Qualification from "./canonical/Qualification";
import { type Product, ProductTypeEnum } from "./Product";

const baseUrl = "/api/qualification";

export default class SimpleQualification
  extends makeResource<SimpleQualification>(baseUrl)
  implements Product
{
  id!: number;
  data!: Qualification;
  productType!: string;

  static years(id: number): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${id}/years`);
  }

  static async import(qualification: SimpleQualification) {
    return post(`${baseUrl}/${qualification.id}/import`, null);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleQualification>(
      ProductTypeEnum.QUALIFICATION,
      baseUrl,
      queryParams
    );
  }
}
