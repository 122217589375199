import { isEmpty } from "lodash";
import { type Product } from "../../types/Product";
import { usePrimaryWidgetFields } from "../../widgets/hooks/usePrimaryWidgetFields.hook";
import { type Widget } from "../../widgets/types/Widget";
import ProductWidgetItem from "../ProductOverviewTable/ProductWidgetItem";
import ProductHeaderFallback from "./ProductHeaderFallback";

type Props = {
  product: Product;
  widgets: Widget[];
};

export default function ProductHeaderName({
  product,
  widgets
}: Readonly<Props>) {
  const fields = usePrimaryWidgetFields(widgets);

  if (isEmpty(fields)) {
    return <ProductHeaderFallback product={product} />;
  }

  return (
    <h1 className="details__content__main-title">
      <ProductWidgetItem field={fields[0]} product={product} />
    </h1>
  );
}
