import { get, makeResource, post } from "@42.nl/spring-connect";
import _, { uniqueId } from "lodash";
import { type QueryParams, search } from "../filters/Search";
import { type LocalizedText } from "../i18n/LocalizedText";
import { getPeriodSequence } from "../periods/PeriodService";
import type Reference from "../references/Reference";
import { getReferenceById } from "../references/ReferenceService";
import { type ProductTreeNode } from "../widgets/WidgetPanel/MatrixWidget/ProductTree/ProductTreeStore/types/ProductTreeNode";
import { ProductTreeNodeTypeEnum } from "../widgets/WidgetPanel/MatrixWidget/ProductTree/ProductTreeStore/types/ProductTreeNodeType";
import { getProductTreeNodeCompositeUid } from "../widgets/WidgetPanel/MatrixWidget/ProductTree/ProductTreeStore/utils/getProductTreeNodeCompositeUid";
import type AcademicSession from "./AcademicSession";
import type AcademicYear from "./AcademicYear";
import { type GroupModule } from "./canonical/GroupModule";
import type Module from "./canonical/Module";
import type ClientOffering from "./canonical/Offering";
import { getTimeBlocks } from "./canonical/Offering";
import { type GroupStudy } from "./GroupStudy";
import { type Product, ProductTypeEnum } from "./Product";
import { type Rule } from "./Rule";

const baseUrl = "/api/module";

export default class SimpleModule
  extends makeResource<SimpleModule>(baseUrl)
  implements Product
{
  id!: number;
  code?: string;
  data!: Module;
  productType!: string;
  offering?: Offering[];
  uid!: string;
  names?: LocalizedText[];

  static groups(product: Product): Promise<GroupStudy[]> {
    if (product.id === undefined) {
      return Promise.resolve([]);
    }

    return get(`${baseUrl}/${product.id}/groups`);
  }

  static years(id: number): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${id}/years`);
  }

  static async import(module: SimpleModule) {
    return post(`${baseUrl}/${module.id}/import`, null);
  }

  static rules(product: Product): Promise<Rule[]> {
    return get(`${baseUrl}/${product.id}/rules`);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleModule>(ProductTypeEnum.MODULE, baseUrl, queryParams);
  }
}

export class Offering {
  period!: AcademicYear;
  blocks?: Block[];
}

export class Block {
  externalId!: string;
  code?: string;
}

export type ModuleRow = {
  structure: GroupModule;
  module: Product;
};

export function sortModules(
  rows: ModuleRow[] | undefined,
  phases: Reference[],
  periods: AcademicSession[],
  timeBlocks: Reference[]
): ModuleRow[] {
  // Set default values of sorting properties so that nulls are sorted first
  const sortedRows = _(rows)
    .map((row) => ({
      row,
      code: _.get(row, "module.code", ""),
      sequence: _.get(row, "structure.sequence", -1),
      phaseSequence: _.get(
        getReferenceById(phases, row.structure.phase),
        "sequence",
        -1
      ),
      periodSequence: getMinPeriodSequence(row.structure?.offerings, periods),
      blockSequence: getMinBlockSequence(row.structure?.offerings, timeBlocks)
    }))
    .orderBy([
      "sequence",
      "phaseSequence",
      "periodSequence",
      "blockSequence",
      "code"
    ])
    .value();

  return sortedRows.map((row) => row.row);
}

function getMinPeriodSequence(
  offerings: ClientOffering[] | undefined,
  periods: AcademicSession[]
) {
  const sequences = _(offerings)
    .map((offering) => getPeriodSequence(periods, offering.period?.id))
    .sort()
    .value();

  return sequences.length > 0 ? sequences[0] : periods.length;
}

function getMinBlockSequence(
  offerings: ClientOffering[] | undefined,
  timeBlocks: Reference[]
) {
  const sequences = getTimeBlocks(offerings, timeBlocks)
    .map((reference) => reference?.sequence)
    .filter((sequence) => sequence !== null && sequence !== undefined)
    .sort();

  return sequences[0] || timeBlocks.length;
}

export function getObjectives(
  moduleRow: ModuleRow
): ProductTreeNode[] | undefined {
  const objectives = moduleRow.module.data.objectives;
  if (!objectives || objectives.length === 0) {
    return undefined;
  }

  return objectives.map((objective) => ({
    key: getProductTreeNodeCompositeUid(
      moduleRow.module.data.uid,
      objective.uid
    ),
    uid: objective.uid,
    type: ProductTreeNodeTypeEnum.OBJECTIVE,
    product: {
      id: parseInt(uniqueId()),
      data: { ...objective, year: moduleRow.module.data.year },
      productType: ProductTypeEnum.OBJECTIVE
    },
    isFetched: true
  }));
}
