import { isEmpty } from "lodash";
import { getText } from "../../../i18n/LocalizedText";
import { type ProductData } from "../../../types/canonical/ProductData";
import { getTemplate } from "../../../types/Product";
import { type WidgetField } from "../../WidgetField/WidgetField";

type Props = {
  field: WidgetField;
  data: ProductData;
};

export function UrlItem({ field, data }: Readonly<Props>) {
  const url = getTemplate(field.name, data);
  if (isEmpty(url) && field.hideEmpty) {
    return <></>;
  }

  return (
    <section className="details__content__section d-flex">
      <a
        className="text-decoration-none"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <span>{getText(field.labels, url)}</span>
      </a>
    </section>
  );
}
