import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { groupBy, isEmpty } from "lodash";
import Loader from "../../../components/Loader/Loader";
import { ProductLink } from "../../../product/ProductLink";
import { displayName, ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import {
  productIsOfType,
  type Product,
  ProductTypeEnum
} from "../../../types/Product";
import { Rule } from "../../../types/Rule";
import SimpleGroup from "../../../types/SimpleGroup";
import SimpleModule from "../../../types/SimpleModule";
import SimpleStudy from "../../../types/SimpleStudy";
import { type WidgetField } from "../../WidgetField/WidgetField";

type Props = {
  field: WidgetField;
  product: Product;
};

async function loadRules(product: Product) {
  let rules: Rule[] = [];

  if (productIsOfType(product, ProductTypeEnum.STUDY)) {
    rules = await SimpleStudy.rules(product);
  } else if (productIsOfType(product, ProductTypeEnum.GROUP)) {
    rules = await SimpleGroup.rules(product);
  } else if (productIsOfType(product, ProductTypeEnum.MODULE)) {
    rules = await SimpleModule.rules(product);
  }

  return groupBy(rules, (rule) => rule.type);
}

export function RulesItem({ field, product }: Readonly<Props>) {
  const { references: linkTypes } = useReferences(
    ReferenceType.LINK,
    product.data.year.id
  );

  const state = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [Rule.QUERY_KEY, product.data.id, product.data.year.id],
    queryFn: () => loadRules(product)
  });

  return (
    <Loader state={state}>
      {(rules) =>
        isEmpty(rules) || field.hideEmpty ? (
          <></>
        ) : (
          <section className="details__content__section d-flex">
            <div className="d-flex flex-column font-family-base">
              {Object.keys(rules).map((type, index, typeRules) => (
                <div
                  key={index}
                  className={classNames({
                    "mb-3": index < typeRules.length - 1
                  })}
                >
                  <div className="mb-2 font-base-strong">
                    {displayName(type, linkTypes, type)}
                  </div>
                  {rules[type].map((rule, rulesIndex) => (
                    <ProductLink
                      key={rulesIndex}
                      product={rule.target}
                      className={classNames(
                        { "mb-2": rulesIndex !== rules[type].length - 1 },
                        "d-inline-block"
                      )}
                    />
                  ))}
                </div>
              ))}
            </div>
          </section>
        )
      }
    </Loader>
  );
}
