import { useState } from "react";
import { ExpandAll } from "../../../components/ExpandAll/ExpandAll";
import { GroupContents } from "../../../groups/GroupContents/GroupContents";
import { ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import { productIsOfType, ProductTypeEnum } from "../../../types/Product";
import { sortGroups } from "../../../types/SimpleGroup";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";

interface StructureWidgetProps extends WidgetRenderVariantProps {
  expanded: boolean;
}

export function StructureWidget({
  widget,
  product,
  expanded
}: Readonly<StructureWidgetProps>) {
  const { references: types } = useReferences(ReferenceType.GROUP_TYPE);
  const [expandAll, setExpandAll] = useState(expanded);

  const toggleExpandAll = (expand: boolean) => setExpandAll(expand);

  const isStudy = productIsOfType(product, ProductTypeEnum.STUDY);
  const isGroup = productIsOfType(product, ProductTypeEnum.GROUP);

  if (!isStudy && !isGroup) {
    return null;
  }

  return (
    <div className="structure">
      <ExpandAll
        expand={expandAll}
        className="pt-3 pb-2"
        onToggle={toggleExpandAll}
      />
      {isStudy && (
        <>
          {sortGroups(product.data.groups, types).map(
            (group, index) =>
              group.group && (
                <GroupContents
                  key={`group-${index}`}
                  group={group.group}
                  year={product.data.year.id}
                  expandAll={expandAll}
                  fields={widget.fields}
                  detailPageType={product.productType}
                />
              )
          )}
        </>
      )}
      {isGroup && (
        <GroupContents
          key="group"
          level={0}
          group={product.data}
          year={product.data.year.id}
          expandAll={expandAll}
          fields={widget.fields}
          detailPageType={product.productType}
        />
      )}
    </div>
  );
}
