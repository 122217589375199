import { WidgetTitle } from "../components/WidgetTitle";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";
import { MatrixWidgetContainer } from "./MatrixWidgetContainer/MatrixWidgetContainer";

export function MatrixWidget({
  widget,
  product
}: Readonly<WidgetRenderVariantProps>) {
  return (
    <div className="mt-2">
      <WidgetTitle widget={widget} />
      <MatrixWidgetContainer widget={widget} product={product} />
    </div>
  );
}
