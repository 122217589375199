import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { DescriptionList } from "../../i18n";
import { type Group } from "../../types/canonical/Group";
import { type ProductType, ProductTypeEnum } from "../../types/Product";
import { type WidgetField } from "../../widgets/WidgetField/WidgetField";
import { GroupModulesList } from "./components/GroupModulesList";
import { GroupModulesOverview } from "./components/GroupModulesOverview";
import { ModeSwitcher } from "./components/ModeSwitcher";

export type GroupExtraModes = "table" | "list";

type GroupExtraProps = {
  group: Group;
  year: string;
  fields: WidgetField[];
  initialMode: GroupExtraModes;
  onMode: (mode: GroupExtraModes) => void;
  isRoot?: boolean;
  detailPageType: ProductType;
};

export function GroupExtra({
  group,
  year,
  fields,
  initialMode,
  onMode,
  isRoot,
  detailPageType
}: Readonly<GroupExtraProps>) {
  const { t } = useTranslation("group");
  const [mode, setMode] = useState<GroupExtraModes>(initialMode);

  const modes: { name: GroupExtraModes; label: string }[] = [
    {
      name: "table",
      label: t("MODES.TABLE")
    },
    {
      name: "list",
      label: t("MODES.LIST")
    }
  ];

  function toggleMode(newMode: GroupExtraModes) {
    setMode(newMode);
    onMode(newMode);
  }

  // When viewing a group as a detail page, it's assumed the description is shown as a separate tab
  const hideDescription = detailPageType === ProductTypeEnum.GROUP && isRoot;

  if (!group.modules?.some((it) => it.module?.code)) {
    if (hideDescription) {
      return null;
    }
    return (
      <DescriptionList
        type="MODULEGROUP_DESCRIPTION"
        year={year}
        descriptions={group?.descriptions}
      />
    );
  }

  return (
    <div className="search-and-results">
      <Row className="my-2">
        <Col sm={9}>
          {!hideDescription && (
            <DescriptionList
              type="MODULEGROUP_DESCRIPTION"
              year={year}
              descriptions={group?.descriptions}
            />
          )}
        </Col>

        <Col sm={3}>
          <ModeSwitcher
            className="float-end d-print-none"
            current={mode}
            modes={modes}
            onChange={toggleMode}
          />
        </Col>
      </Row>

      {mode === "table" ? (
        <GroupModulesOverview
          group={group}
          year={year}
          fields={fields}
          isRoot={isRoot}
        />
      ) : (
        <GroupModulesList group={group} year={year} />
      )}
    </div>
  );
}
