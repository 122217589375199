import { type EpicTableSortDirection } from "@42.nl/ui";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import i18n, { t } from "i18next";
import { map } from "lodash";
import { languageCodeToQueryName } from "../../i18n/LanguageSwitcher/LanguageSwitcher";
import { type ProductType } from "../../types/Product";

export class SortOption {
  column: string;
  direction: EpicTableSortDirection;
  sort: string;
  displayName: string;
  icon: React.ReactElement;

  constructor(
    column: string,
    direction: EpicTableSortDirection,
    displayName: string,
    icon: React.ReactElement
  ) {
    this.column = column;
    this.direction = direction;
    this.sort = `${column},${direction}`;
    this.displayName = displayName;
    this.icon = icon;
  }
}

interface SortOptionConfig {
  field: string;
  direction: EpicTableSortDirection;
  translationKey: string;
  icon: React.ReactElement;
}

const commonSortOptions: SortOptionConfig[] = [
  {
    field: "language",
    direction: "ASC",
    translationKey: "sort:OPTIONS.NAME_ASC",
    icon: <SortAscendingOutlined />
  },
  {
    field: "language",
    direction: "DESC",
    translationKey: "sort:OPTIONS.NAME_DESC",
    icon: <SortDescendingOutlined />
  },
  {
    field: "code",
    direction: "ASC",
    translationKey: "sort:OPTIONS.CODE_ASC",
    icon: <SortAscendingOutlined />
  },
  {
    field: "code",
    direction: "DESC",
    translationKey: "sort:OPTIONS.CODE_DESC",
    icon: <SortDescendingOutlined />
  }
];

const creditsSortOptions: SortOptionConfig[] = [
  {
    field: "credits",
    direction: "ASC",
    translationKey: "sort:OPTIONS.CREDITS_ASC",
    icon: <CaretDownOutlined />
  },
  {
    field: "credits",
    direction: "DESC",
    translationKey: "sort:OPTIONS.CREDITS_DESC",
    icon: <CaretUpOutlined />
  }
];

export function getSortOptions(productType?: ProductType): SortOption[] {
  const sortOptionConfigs =
    productType === "FACULTY"
      ? commonSortOptions
      : [...commonSortOptions, ...creditsSortOptions];

  return map(
    sortOptionConfigs,
    (config) =>
      new SortOption(
        config.field === "language"
          ? languageCodeToQueryName(i18n.language)
          : config.field,
        config.direction,
        t(config.translationKey),
        config.icon
      )
  );
}
