import { type Url, urlBuilder, useQueryParams } from "@42.nl/react-url/lib";
import { useTranslation } from "react-i18next";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { useDataOnly } from "@hooks/queryParams.hook";
import { AppFrame, Loader } from "../../components";
import { toQueryParams } from "../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs";
import { PrintButton } from "../../components/Print/PrintButton";
import ProductHeader from "../../product/ProductHeader/ProductHeader";
import { ProductPrint } from "../../product/ProductPrint";
import { useActiveMainTab } from "../../tabs/context/hooks/useActiveMainTab";
import { useSubTabs } from "../../tabs/context/hooks/useSubTabs";
import SubTabsNav from "../../tabs/SubTabsNav";
import { getActiveSubTab } from "../../tabs/utils/subTabUtils";
import { type ProductData } from "../../types/canonical/ProductData";
import Faculty from "../../types/Faculty";
import {
  type ProductDetailPathParams,
  type ProductDetailQueryParams,
  useProductQuery
} from "../../types/Product";
import { WidgetButtons } from "../../widgets/WidgetDetail/WidgetButtons";
import { WidgetDetail } from "../../widgets/WidgetDetail/WidgetDetail";
import ProductDetailYearDropdown from "../../years/components/YearDropdown/ProductDetailYearDropdown";
import { FACULTY_APP_URL, toFacultiesApp } from "../FacultyLinks";
import { defaultFacultyDetailQueryParams } from "./FacultyDetailQueryParams";

export async function loadFaculty(
  code: string,
  queryParams: ProductDetailQueryParams
) {
  return Faculty.findOne({
    ...queryParams,
    code
  });
}

export default function FacultyDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useQueryParams<ProductDetailQueryParams>({
    location,
    defaultQueryParams: defaultFacultyDetailQueryParams(),
    debugName: "FacultyDetail"
  });
  const isDataOnly = useDataOnly();

  const { code = "" } = useParams<ProductDetailPathParams>();
  const { t } = useTranslation(["translation", "faculty"]);

  const productState = useProductQuery<Faculty>({
    productType: "faculty",
    loadFunction: loadFaculty,
    code,
    queryParams,
    filterChanged
  });

  const activeMainTab = useActiveMainTab();
  const subTabs = useSubTabs();
  const activeSubTab = getActiveSubTab(subTabs, queryParams.tab);

  function filterChanged(name: string, value: string | number) {
    navigate(toFacultyDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
  }

  return (
    <Loader
      state={productState}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toFacultiesApp(toQueryParams(activeMainTab, queryParams.year))}
          replace
        />
      )}
    >
      {(faculty) => (
        <AppFrame
          breadcrumbs={
            !isDataOnly && (
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link
                    to={toFacultiesApp(
                      toQueryParams(activeMainTab, faculty.data.year.id)
                    )}
                  >
                    {t("faculty:FACULTIES")}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{faculty.data.code}</BreadcrumbItem>
              </Breadcrumb>
            )
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className={`
                details__content__main me-auto
                ${isDataOnly ? "mt-4" : ""}
              `}
            >
              <ProductHeader product={faculty} tabId={activeMainTab?.id} />

              <Row style={{ minHeight: "46px" }} className="mb-3">
                <Col m={12} md={12} lg={12} xs={12}>
                  <ProductDetailYearDropdown
                    yearExternalId={queryParams.year}
                    productYearsPromise={Faculty.years}
                    productId={faculty.id}
                    onChange={filterChanged}
                  />
                  <WidgetButtons product={faculty}>
                    <PrintButton>
                      <ProductPrint tabs={subTabs} product={faculty} />
                    </PrintButton>
                  </WidgetButtons>
                </Col>
              </Row>

              {!isDataOnly && (
                <SubTabsNav
                  subTabs={subTabs}
                  activeTabName={activeSubTab?.name}
                  product={faculty}
                  toggle={filterChanged}
                />
              )}

              <TabContent activeTab={activeSubTab?.name}>
                {subTabs.map((subTab) => (
                  <TabPane tabId={subTab.name} key={subTab.id}>
                    <WidgetDetail
                      tabId={subTab.id}
                      product={faculty}
                      expanded={true}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const FACULTY_DETAIL_URL = `${FACULTY_APP_URL}/:code`;

function toFacultyDetail(
  { code }: ProductDetailPathParams,
  queryParams: ProductDetailQueryParams
): Url {
  return urlBuilder({
    url: FACULTY_DETAIL_URL,
    pathParams: {
      code: encodeURIComponent(code)
    },
    queryParams: queryParams
  });
}

export function facultyDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & ProductData) {
  return toFacultyDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
