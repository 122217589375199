import { makeResource } from "@42.nl/spring-connect";
import { type LocalizedText } from "../i18n/LocalizedText";

const baseUrl = "/api/filters";

export default class Filter extends makeResource<Filter>(baseUrl) {
  id!: number;
  entityType!: string;
  name!: string;
  values?: string;
  exact!: boolean;
  visible!: boolean;
  sequence?: number;
  valueType!: string;
  valueSubType?: string;
  displayType!: FilterDisplayType;
  maxDisplayed?: number;
  labels!: LocalizedText[];
  tooltips!: LocalizedText[];

  constructor() {
    super();

    this.visible = true;
    this.displayType = FilterDisplayType.SHOW_ALL;
  }

  static readonly QUERY_KEY = "filters";
}

export type FilterData = Filter & {
  displayLabel: string;
  displayTooltip?: string;
  currentValue: string[];
};

export enum FilterDisplayType {
  SHOW_ALL = "SHOW_ALL",
  SHOW_MORE = "SHOW_MORE",
  POPUP = "POPUP"
}

export function getNumbers(filter: Filter, defaultValues: number[]) {
  if (filter.values) {
    return filter.values
      .split(",")
      .map((val) => parseFloat(val.trim()))
      .filter((val) => !isNaN(val));
  }

  return defaultValues;
}
