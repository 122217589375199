import { useMemo } from "react";
import { type UseQueryResult } from "@tanstack/react-query";
import { chain } from "lodash";
import { widgetApi } from "../service/widgetApi";
import { type Widget } from "../types/Widget";

export const useVisibleSortedWidgetsForTab = (
  tabId: number | undefined
): UseQueryResult<Widget[]> => {
  const queryResult = widgetApi.list.useQuery({
    variables: { tabId },
    enabled: !!tabId
  });

  const processedData: Widget[] = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return chain(queryResult.data)
      .filter("visible")
      .sortBy(["sequence", "id"])
      .value();
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: processedData
  } as UseQueryResult<Widget[]>;
};
