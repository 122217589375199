import { Icon, type IconType } from "@42.nl/ui";

type Props = {
  isToggled: boolean;
  onClick: () => void;
  icon: IconType;
  className?: string;
};

export default function MobileMenuToggler({
  isToggled,
  onClick,
  icon,
  className
}: Readonly<Props>) {
  return (
    <button
      type="button"
      className={`mobile-menu-toggler btn btn-default d-flex shadow-none ${className}`}
      data-toggle="canvas"
      data-target="#mobile-menu-canvas"
      aria-expanded={isToggled}
      aria-controls="mobile-menu-canvas"
      onClick={onClick}
    >
      <Icon icon={icon} />
    </button>
  );
}
