import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type GroupExtraModes } from "../GroupContents/GroupExtra";
import {
  type StructureGroupSettings,
  type StructureGroupsState
} from "./structureStore";

const initialState: StructureGroupsState = {};

const defaultGroupSettings: Partial<StructureGroupSettings> = {
  isExpanded: false,
  isAllExpanded: false,
  isFetched: false,
  mode: "table"
};

const structureSlice = createSlice({
  name: "structureGroups",
  initialState,
  reducers: {
    setGroup: (
      state,
      action: PayloadAction<{
        code: string;
        settings?: Partial<StructureGroupSettings>;
      }>
    ) => {
      const { code, settings } = action.payload;
      state[code] = {
        ...defaultGroupSettings,
        ...state[code],
        ...settings,
        code
      };
    },
    toggleGroup: (state, action: PayloadAction<{ code: string }>) => {
      const { code } = action.payload;
      const group = state[code];
      if (group) {
        group.isExpanded = !group.isExpanded;
      }
    },
    setGroupMode: (
      state,
      action: PayloadAction<{ code: string; mode: GroupExtraModes }>
    ) => {
      const { code, mode } = action.payload;
      const group = state[code];
      if (group) {
        group.mode = mode;
      }
    }
  }
});

export const { setGroup, toggleGroup, setGroupMode } = structureSlice.actions;
export default structureSlice.reducer;
