import { type LocalizedText } from "../../i18n/LocalizedText";
import type Additional from "./Additional";
import type Appraisal from "./Appraisal";
import type Capacity from "./Capacity";
import type Credits from "./Credits";
import type Description from "./Description";
import { type GroupGroup } from "./GroupGroup";
import { type GroupModule } from "./GroupModule";
import type License from "./License";
import type Link from "./Link";
import type MethodSchema from "./MethodSchema";
import type Objective from "./Objective";
import type Offering from "./Offering";
import type Reference from "./Reference";
import type Relation from "./Relation";
import { type StudyGroup } from "./StudyGroup";
import type Subject from "./Subject";
import type Value from "./Value";

export type ProductData = {
  id?: string;
  uid: string;
  code?: string;
  type?: string;
  key?: number; // Curriculum ID
  names?: LocalizedText[];
  year: Reference;
  faculty?: Reference;
  organisation?: Reference;
  additional?: Additional;
  capacity?: Capacity;
  credits?: Credits;
  descriptions?: Description[];
  groups?: StudyGroup[] | GroupGroup[];
  relations?: Relation[];
  objectives?: Objective[];
  offerings?: Offering[];
  licenses?: License[];
  links?: Link[];
  subjects?: Subject[];
  assessments?: Appraisal[];
  methods?: MethodSchema[];
  modules?: GroupModule[];
  values?: Value[];
};

export function getDefaultCode(data: ProductData) {
  return data.code || data.id || data.uid;
}

export function getAllValues(data: ProductData) {
  let values: Value[] = [];
  if (data.values) {
    values = values.concat(data.values);
  }
  if (data.credits?.values) {
    data.credits.values.forEach((value) => {
      const name = `credits.${value.name}`;
      values.push({ ...value, name });
    });
  }
  return values;
}
