import { get, makeResource } from "@42.nl/spring-connect";

const baseUrl = "/api/labels";

export default class Label extends makeResource<Label>(baseUrl) {
  id!: number;
  key!: string;
  language!: string;
  value!: string;

  static getLanguages(): Promise<string[]> {
    return get(baseUrl + "/languages");
  }

  static readonly QUERY_KEY = "labels";
}
