import { ContentState } from "@42.nl/ui";

type Props = {
  url: string;
};

export default function Redirect({ url }: Readonly<Props>) {
  window.location.assign(url);
  return <ContentState mode="loading" title="" />;
}
