import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";
import { toAbout } from "../../../../../../About/links";
import { type CurrentUser } from "../../../../../../authentication/CurrentUser";
import { AdminNavLink } from "../../../../../AdminNavLink/AdminNavLink";
import LogoutDropdownItem from "../LogoutDropdownItem/LogoutDropdownItem";
import AdministrationNav from "./AdministrationNav";

type Props = {
  user: CurrentUser | undefined;
  isMobile: boolean;
};

export default function UserNav({ user, isMobile }: Readonly<Props>) {
  if (!user) {
    return <></>;
  }

  return (
    <Nav className={isMobile ? "mobile-menu-extra" : "d-none d-lg-flex"}>
      <AdministrationNav />

      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret color="outline-secondary">
          <UserOutlined className="me-1" />
          <span>{user.name}</span>
        </DropdownToggle>

        <DropdownMenu>
          <LogoutDropdownItem />
        </DropdownMenu>
      </UncontrolledDropdown>

      <NavItem className="d-flex">
        <AdminNavLink to={toAbout()}>
          <QuestionCircleOutlined />
        </AdminNavLink>
      </NavItem>
    </Nav>
  );
}
