import { type SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
  expandable?: boolean;
  expanded?: boolean;
};

export function Compact(props: Readonly<Props>) {
  const { children, expanded } = props;

  if (expanded === true) {
    return <div>{children}</div>;
  }

  return <CompactSection {...props} />;
}

function CompactSection({ children, expandable }: Readonly<Props>) {
  const MAX_HEIGHT = 180;
  const REMAINING_THRESHOLD = 25;

  const { t } = useTranslation(["translation"]);

  const [compact, setCompact] = useState(true);
  const [height, setHeight] = useState<number>(0);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Use timeout so that div has been rendered
    const timeoutId = window.setTimeout(() => {
      if (divRef && divRef.current) {
        setHeight(divRef.current.clientHeight);
      }
    }, 0);

    return () => {
      window.clearTimeout(timeoutId);
    };
  });

  function expand(event: SyntheticEvent) {
    event.stopPropagation();
    setCompact(false);
  }

  const overflow =
    height > MAX_HEIGHT && height - MAX_HEIGHT > REMAINING_THRESHOLD;
  if (!overflow) {
    return <div ref={divRef}>{children}</div>;
  }

  return (
    <>
      <div className={`${compact ? "compact" : ""}`}>
        <div ref={divRef}>{children}</div>
      </div>
      {expandable !== false && (
        <span onClick={expand} className="compact-toggle">
          {compact ? t("SHOW_MORE") : ""}
        </span>
      )}
    </>
  );
}
