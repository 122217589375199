import { getText } from "../../i18n/LocalizedText";
import { getDefaultCode } from "../../types/canonical/ProductData";
import { type Product } from "../../types/Product";

export type FilterOption = {
  value: string;
  label: string;
};

export function formatProductAsFilterOption(product: Product): FilterOption {
  const code = getDefaultCode(product.data) || "";
  const label = getText(product.data.names) || "";

  return {
    value: code,
    label: `(${code}) ${label}`
  };
}

export function formatProductsAsFilterOptions(
  products: Product[]
): FilterOption[] {
  if (products.length === 0) return [];

  return products.map(formatProductAsFilterOption);
}
