import { get } from "@42.nl/spring-connect";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

type Props = {
  children: React.ReactNode;
};

type Customer = {
  name: string;
  style: string;
};

async function getCustomer(): Promise<Customer> {
  return await get("/api/customer");
}

export function CustomerStyle({ children }: Readonly<Props>) {
  const { i18n } = useTranslation();
  const state = useQuery({
    queryKey: ["customer"],
    queryFn: getCustomer
  });

  return (
    <Loader state={state}>
      {(customer) => (
        <div
          className={`customer-body customer-body-lang-${i18n.language} sg-h-full sg-w-full sg-flex sg-flex-col`}
        >
          <style>{customer.style}</style>
          {children}
        </div>
      )}
    </Loader>
  );
}
