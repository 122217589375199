import { type CSSProperties, useMemo } from "react";
import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { type ProductTreeBulkToggleExpanderButtonProps } from "./types/ProductTreeBulkToggleExpanderButton";

const ExpandButton = ({
  label,
  onAction
}: ProductTreeBulkToggleExpanderButtonProps) => {
  const styleToHideTooltipOnHover = useMemo(
    (): CSSProperties => ({
      pointerEvents: "none"
    }),
    []
  );

  return (
    <Tooltip
      title={label}
      aria-label={label}
      placement="bottomRight"
      overlayStyle={styleToHideTooltipOnHover}
      overlayInnerStyle={styleToHideTooltipOnHover}
    >
      <Button
        type="default"
        icon={<VerticalAlignMiddleOutlined aria-hidden={true} />}
        onClick={onAction}
      />
    </Tooltip>
  );
};

export default ExpandButton;
