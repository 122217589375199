import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { groupDetailUrl } from "../../../groups/GroupDetail/GroupDetail";
import { getText } from "../../../i18n/LocalizedText";
import { getUniqueKey, type GroupStudy } from "../../../types/GroupStudy";
import {
  productIsOfType,
  type Product,
  ProductTypeEnum
} from "../../../types/Product";
import SimpleGroup from "../../../types/SimpleGroup";
import SimpleModule from "../../../types/SimpleModule";
import { type WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  hideHeader?: boolean;
  isTitle: boolean;
};

class GroupItem {
  groupStudy: GroupStudy;
  name: string;
  key: string;

  constructor(groupStudy: GroupStudy) {
    this.groupStudy = groupStudy;
    this.name = `${getText(groupStudy.group.data.names)} (${groupStudy.study.data.code})`;
    this.key = getUniqueKey(groupStudy);
  }
}

async function loadGroups(product: Product) {
  let items: GroupStudy[] = [];

  if (productIsOfType(product, ProductTypeEnum.MODULE)) {
    items = await SimpleModule.groups(product);
  } else if (productIsOfType(product, ProductTypeEnum.GROUP)) {
    items = await SimpleGroup.parents(product);
  }

  return items
    .map((item) => new GroupItem(item))
    .sort((item1, item2) => item1.name.localeCompare(item2.name));
}

export function GroupsItem({
  field,
  type,
  product,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const state = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["productGroups", product.data.id, product.data.year.id],
    queryFn: () => loadGroups(product)
  });

  return (
    <Loader state={state}>
      {(items) => (
        <FieldSection
          field={field}
          type={type}
          isEmpty={isEmpty(items)}
          hideHeader={hideHeader}
          isTitle={isTitle}
        >
          {() => (
            <>
              {items.map((item) => (
                <Link
                  key={item.key}
                  to={groupDetailUrl(item.groupStudy.group.data)}
                  className="d-block"
                >
                  {item.name}
                </Link>
              ))}
            </>
          )}
        </FieldSection>
      )}
    </Loader>
  );
}
