import { type Url, urlBuilder, useQueryParams } from "@42.nl/react-url/lib";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { useDataOnly } from "@hooks/queryParams.hook";
import { AppFrame, Loader } from "../../components";
import { toQueryParams } from "../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs";
import ProductHeader from "../../product/ProductHeader/ProductHeader";
import { useActiveMainTab } from "../../tabs/context/hooks/useActiveMainTab";
import { useSubTabs } from "../../tabs/context/hooks/useSubTabs";
import SubTabsNav from "../../tabs/SubTabsNav";
import { getActiveSubTab } from "../../tabs/utils/subTabUtils";
import { type Group } from "../../types/canonical/Group";
import { GROUP_APP_URL, toGroupsApp } from "../../types/GroupLinks";
import {
  type ProductDetailPathParams,
  type ProductDetailQueryParams,
  useProductQuery
} from "../../types/Product";
import SimpleGroup from "../../types/SimpleGroup";
import { WidgetButtons } from "../../widgets/WidgetDetail/WidgetButtons";
import { WidgetDetail } from "../../widgets/WidgetDetail/WidgetDetail";
import ProductDetailYearDropdown from "../../years/components/YearDropdown/ProductDetailYearDropdown";
import { structureStore } from "../GroupStructure/structureStore";
import { defaultGroupDetailQueryParams } from "./GroupDetailQueryParams";

async function loadGroup(code: string, queryParams: ProductDetailQueryParams) {
  return SimpleGroup.findOne({
    ...queryParams,
    code
  });
}

export default function GroupDetail() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useQueryParams<ProductDetailQueryParams>({
    location,
    defaultQueryParams: defaultGroupDetailQueryParams(),
    debugName: "GroupDetail"
  });

  const isDataOnly = useDataOnly();

  const { code = "" } = useParams<ProductDetailPathParams>();
  const { t } = useTranslation(["translation", "group"]);

  const productState = useProductQuery<SimpleGroup>({
    productType: "group",
    loadFunction: loadGroup,
    code,
    queryParams,
    filterChanged
  });

  const activeMainTab = useActiveMainTab();
  const subTabs = useSubTabs();
  const activeSubTab = getActiveSubTab(subTabs, queryParams.tab);

  function filterChanged(name: string, value: string | number) {
    navigate(toGroupDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
  }

  return (
    <Loader
      state={productState}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toGroupsApp(toQueryParams(activeMainTab, queryParams.year))}
          replace
        />
      )}
    >
      {(group) => (
        <AppFrame
          breadcrumbs={
            !isDataOnly && (
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link
                    to={toGroupsApp(
                      toQueryParams(activeMainTab, group.data.year.id)
                    )}
                  >
                    {t("group:GROUPS")}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{group.data.code}</BreadcrumbItem>
              </Breadcrumb>
            )
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className={`
                details__content__main me-auto
                ${isDataOnly ? "mt-4" : ""}
              `}
            >
              <Provider store={structureStore}>
                <ProductHeader product={group} tabId={activeMainTab?.id} />

                <Row style={{ minHeight: "46px" }}>
                  <Col m={12} md={12} lg={12} xs={12}>
                    <ProductDetailYearDropdown
                      yearExternalId={queryParams.year}
                      productYearsPromise={SimpleGroup.years}
                      productId={group.id}
                      onChange={filterChanged}
                    />
                    <WidgetButtons product={group} />
                  </Col>
                </Row>

                {!isDataOnly && (
                  <SubTabsNav
                    subTabs={subTabs}
                    activeTabName={activeSubTab?.name}
                    product={group}
                    toggle={filterChanged}
                  />
                )}

                <TabContent activeTab={activeSubTab?.name}>
                  {subTabs.map((subTab) => (
                    <TabPane tabId={subTab.name} key={subTab.id}>
                      <WidgetDetail
                        tabId={subTab.id}
                        product={group}
                        expanded={false}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Provider>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const GROUP_DETAIL_URL = `${GROUP_APP_URL}/:code`;

function toGroupDetail(
  { code }: ProductDetailPathParams,
  queryParams: ProductDetailQueryParams
): Url {
  return urlBuilder({
    url: GROUP_DETAIL_URL,
    pathParams: {
      code: encodeURIComponent(code)
    },
    queryParams: queryParams,
    defaultQueryParams: defaultGroupDetailQueryParams()
  });
}

export function groupDetailUrl({
  id,
  code,
  uid,
  year,
  mainTab
}: {
  mainTab?: string;
} & Group) {
  return toGroupDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
