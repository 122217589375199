import { AsyncContent, ContentState } from "@42.nl/ui";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { CardBody } from "reactstrap";
import { tabApi } from "../../tabs/service/tabApi";
import type Tab from "../../tabs/types/Tab";
import { tabIsParent } from "../../tabs/utils/parentTabUtils";
import { getTabUrl } from "../../tabs/utils/tabUrlUtils";
import SimpleAppFrame from "../SimpleAppFrame/SimpleAppFrame";

export default function NotFound() {
  const state = tabApi.list.useQuery();

  return (
    <AsyncContent state={state}>
      {(tabs) => <DefaultRedirect tabs={tabs} />}
    </AsyncContent>
  );
}

type Props = {
  tabs: Tab[];
};

function DefaultRedirect({ tabs }: Readonly<Props>) {
  const { t } = useTranslation("not-found");

  const defaultTab = tabs
    .toSorted((a, b) => a.sequence - b.sequence)
    .find(tabIsParent);

  const defaultUrl = getTabUrl(defaultTab);
  if (defaultUrl) {
    return <Navigate to={defaultUrl} replace />;
  }

  return (
    <SimpleAppFrame showBackToLogin={false}>
      <CardBody>
        <ContentState title={t("TITLE")} subTitle={t("MESSAGE")} mode="error" />
      </CardBody>
    </SimpleAppFrame>
  );
}
