import { useClearErrors } from "@42.nl/react-error-store";
import { Button, JarbInput, SubmitButton } from "@42.nl/ui";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../i18n/LanguageSelect";

export type LabelFormData = {
  id?: number;
  key?: string;
  value?: string;
  language?: string;
};

type Props = {
  initialValues: LabelFormData;
  onCancel: () => void;
  onSubmit: (data: LabelFormData) => void;
};

export default function LabelForm(props: Readonly<Props>) {
  const { onCancel, onSubmit, initialValues } = props;

  // Clear previous back-end errors if this form was shown before
  useClearErrors();
  const { t } = useTranslation(["label", "translation"]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <JarbInput
            id="key"
            name="key"
            type="text"
            jarb={{
              validator: "Label.key",
              label: t("label:COLUMNS.KEY")
            }}
            label={t("label:COLUMNS.KEY")}
          />

          <LanguageSelect
            name="language"
            label={t("label:COLUMNS.LANGUAGE")}
            validator="Label.language"
          />

          <JarbInput
            id="value"
            name="value"
            type="text"
            jarb={{
              validator: "Label.value",
              label: t("label:COLUMNS.VALUE")
            }}
            label={t("label:COLUMNS.VALUE")}
          />

          <div className="d-flex justify-content-end gap-2 mb-2">
            <Button onClick={onCancel} color="secondary">
              {t("translation:CANCEL")}
            </Button>
            <SubmitButton inProgress={submitting}>
              {t("translation:SUBMIT_BUTTON")}
            </SubmitButton>
          </div>
        </form>
      )}
    />
  );
}
