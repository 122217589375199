export function replaceVariables(
  template: string,
  variables: { [key: string]: string }
) {
  let interpolated = template;
  for (const [key, value] of Object.entries(variables)) {
    const variableRegex = new RegExp(`\\$\\{\\s*${key}\\s*\\}`, "g");
    interpolated = interpolated.replace(variableRegex, value);
  }

  // Replace all unresolved variables with empty string
  const variableRegex = /\$\{.+\}/;
  return interpolated.replace(variableRegex, "").trim();
}
