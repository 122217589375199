import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "reactstrap";
import useQueryParams, { useDataOnly } from "@hooks/queryParams.hook";
import i18n from "../../../../../core/i18n";
import { useAuthentication } from "../../../../authentication/useAuthentication";
import { LanguageSwitcher } from "../../../../i18n";
import Logo from "../../../Logo/Logo";
import MainTabs from "./components/MainTabs/MainTabs";
import MobileMenu from "./components/Menu/MobileMenu/MobileMenu";
import MobileMenuOverlay from "./components/Menu/MobileMenu/MobileMenuOverlay";
import MobileMenuToggler from "./components/Menu/MobileMenu/MobileMenuToggler";
import UserNav from "./components/Menu/UserNav";

export default function TopBar() {
  const { t } = useTranslation(["topbar", "translation"]);

  const isDataOnly = useDataOnly();
  const { language } = useQueryParams<{
    language: "en" | "nl" | "switcher";
  }>();

  const [isToggled, setToggled] = useState(false);

  const toggle = () => setToggled(!isToggled);

  const authentication = useAuthentication();

  return isDataOnly ? (
    <>
      {language === "switcher" && (
        <div className="topbar is-data-only">
          <div className="d-flex flex-row align-items-center justify-content-end mt-2 mb-2 gap-2 navbar-nav">
            <LanguageSwitcher />
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <MobileMenuOverlay isToggled={isToggled} />

      <div className="d-flex flex-column align-items-center topbar border-0">
        <Navbar className="d-flex flex-row justify-content-between extra-nav navbar-nav">
          <Logo className="flex-grow-1" language={i18n.language} />
          <div className="d-flex flex-row align-self-center gap-md-2">
            <LanguageSwitcher />
            <UserNav user={authentication.currentUser} isMobile={false} />
            <MobileMenuToggler
              onClick={toggle}
              isToggled={isToggled}
              aria-label="help"
              icon="menu"
              className="btn-open-menu d-lg-none"
            />
          </div>
        </Navbar>

        <MainTabs closeMobileMenu={() => setToggled(false)} />

        <MobileMenu
          headerText={t("TOPBAR.MENU")}
          isToggled={isToggled}
          user={authentication.currentUser}
          onClick={toggle}
          closeMobileMenu={() => setToggled(false)}
        />
      </div>
    </>
  );
}
