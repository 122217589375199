import { makeResource } from "@42.nl/spring-connect";
import type AcademicYear from "./AcademicYear";

const baseUrl = "/api/session";

export default class AcademicSession extends makeResource<AcademicSession>(
  baseUrl
) {
  id!: number;
  externalId!: string;
  code?: string;
  dutchName?: string;
  englishName?: string;
  startDate!: Date;
  endDate!: Date;
  year!: AcademicYear;
  sequence!: number;
}
