import { type HTMLAttributes } from "react";
import { ProductTreeNodeTypeEnum } from "../../../../widgets/WidgetPanel/MatrixWidget/ProductTree/ProductTreeStore/types/ProductTreeNodeType";
import GroupIcon from "./Group.icon";
import ModuleIcon from "./Module.icon";
import ObjectiveIcon from "./Objective.icon";

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  icon: keyof typeof ProductTreeNodeTypeEnum;
}

const Icons: Record<
  ProductTreeNodeTypeEnum,
  { Icon: () => React.JSX.Element }
> = {
  [ProductTreeNodeTypeEnum.STUDY_OR_GROUP_TO_GROUP]: {
    Icon: GroupIcon
  },
  [ProductTreeNodeTypeEnum.GROUP_TO_MODULE]: {
    Icon: ModuleIcon
  },
  [ProductTreeNodeTypeEnum.OBJECTIVE]: {
    Icon: ObjectiveIcon
  }
};

const ProductTypeIcon = ({ icon }: Readonly<IconProps>) => {
  const iconProps = Icons[icon];
  if (!iconProps) {
    return null;
  }
  const { Icon: IconComponent } = iconProps;

  return (
    <div className="d-inline-flex align-items-center">
      <IconComponent />
    </div>
  );
};

export default ProductTypeIcon;
