import { getListParameter } from "../parameters/Parameter";
import AcademicYear from "../types/AcademicYear";

class YearService {
  private years: AcademicYear[] = [];

  constructor() {
    this.loadYears = this.loadYears.bind(this);
  }

  async loadYears() {
    this.years = await AcademicYear.page({
      sort: ["startDate,desc"],
      size: 9999
    }).then((page) =>
      page.content.filter((year) => this.isVisible(year.externalId))
    );
  }

  getYears() {
    return this.years;
  }

  getCurrentYear() {
    return this.years.filter((year) => year.active)[0] || this.years[0] || {};
  }

  getYear(externalId?: string) {
    if (!externalId) {
      return this.getCurrentYear();
    }
    return this.getYearByExternalId(externalId);
  }

  getYearByExternalId(externalId: string) {
    return this.years.find((year) => year.externalId === externalId);
  }

  isVisible(externalId: string) {
    const visibleYears = getListParameter("visible.years", []);
    return !visibleYears.length || visibleYears.includes(externalId);
  }
}

const instance = new YearService();

export function getYearService(): YearService {
  return instance;
}
