import { Outlet, useLocation } from "react-router-dom";
import NotFound from "../../app/authentication/NotFound/NotFound";
import Footer from "../../app/components/AppFrame/components/Footer/Footer";
import TopBar from "../../app/components/AppFrame/components/TopBar/TopBar";
import EnvBanner from "../../app/components/EnvBanner/EnvBanner";

export default function Layout() {
  const location = useLocation();

  if (location.pathname === "/") {
    return <NotFound />;
  }

  return (
    <>
      <EnvBanner />
      <TopBar />
      <div className="main-wrapper">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
