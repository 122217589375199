import { getApi, type Page, type QueryParams } from "@42.nl/spring-connect";
import {
  type AxiosRequestConfig,
  type AxiosResponse,
  type Method
} from "axios";

type BaseUrl = string;
type Form = { id?: string | number } & Record<string, unknown>;

const apiRequest = async <T>({
  method,
  baseUrl,
  payload = null,
  queryParams = null
}: {
  method: Method;
  baseUrl: string;
  payload?: Form | null;
  queryParams?: QueryParams | null;
}): Promise<T> => {
  const api = getApi();
  const config: AxiosRequestConfig = {
    method,
    baseURL: baseUrl,
    ...(method === "put" || method === "post"
      ? { data: payload }
      : { params: queryParams })
  };

  const response: AxiosResponse<T> = await api.request<T>(config);
  return response.data;
};

const getRequest = async <T>(
  baseUrl: BaseUrl,
  queryParams?: QueryParams
): Promise<T> => {
  return apiRequest<T>({
    method: "get",
    baseUrl,
    queryParams
  });
};

const getPagedRequest = async <T>(
  baseUrl: BaseUrl,
  queryParams?: QueryParams
): Promise<Page<T>> => {
  return getRequest<Page<T>>(baseUrl, queryParams);
};

const postRequest = async <T>(
  baseUrl: BaseUrl,
  payload: Form | null
): Promise<T> => {
  return apiRequest<T>({
    method: "post",
    baseUrl,
    payload
  });
};

const saveRequest = async <T>(baseUrl: BaseUrl, form: Form): Promise<T> => {
  const id = form.id;
  const method: Method = id ? "put" : "post";
  const url: string = id ? `${baseUrl}/${id}` : baseUrl;

  return apiRequest<T>({
    method,
    baseUrl: url,
    payload: form
  });
};

const deleteRequest = async <T>(baseUrl: BaseUrl, form: Form): Promise<T> => {
  const url = `${baseUrl}/${form.id}`;

  return apiRequest<T>({
    method: "delete",
    baseUrl: url
  });
};

const buildBaseUrl = (baseUrl: string): string => {
  return `/api${baseUrl}`;
};

export {
  getRequest,
  saveRequest,
  postRequest,
  deleteRequest,
  getPagedRequest,
  buildBaseUrl
};
