export const capitalize = (word: string) =>
  `${word[0].toUpperCase()}${word.substring(1)}`;

export const toPascalCase = (string: string) => {
  // snake case or kebak case
  const splitByWord = string.split(/_|-/g);
  return splitByWord.reduce((prev, word) => `${prev}${capitalize(word)}`, "");
};

export const toCamelCase = (string: string) => {
  const pascalCase = toPascalCase(string);
  return `${pascalCase[0].toLowerCase()}${pascalCase.substring(1)}`;
};
