import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

type PageSizeProps = {
  value: number;
  useBigSizes: boolean;
  onChange: (size: number) => void;
  className?: string;
};

export function PageSize({
  value,
  useBigSizes,
  onChange,
  className
}: Readonly<PageSizeProps>) {
  const sizes = useBigSizes ? [40, 80, 120] : [10, 20, 30, 50];

  const { t } = useTranslation(["translation"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      toggle={toggle}
      className={`pagination ms-auto ${className}`}
      direction="down"
    >
      <DropdownToggle nav caret className="px-0">
        {t("translation:SHOW_X_RESULTS", { size: value })}
      </DropdownToggle>
      <DropdownMenu>
        {sizes.map((size) => (
          <DropdownItem
            key={size}
            className={`clickable ${value === size ? "active" : ""}`}
            tag="div"
            color="link"
            onClick={() => onChange(size)}
          >
            {t("translation:SHOW_X_RESULTS", { size: size })}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
