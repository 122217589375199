import { useMemo } from "react";
import { orderBy } from "lodash";
import { Table, type TableRecord } from "../../../components/Table/Table";
import { getText } from "../../../i18n/LocalizedText";
import ModuleCell from "../../../modules/ModuleCell";
import ModuleLink from "../../../modules/ModuleLink";
import { getPeriodService } from "../../../periods/PeriodService";
import { ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import { getDefaultCode } from "../../../types/canonical/ProductData";
import { type ModuleRow, sortModules } from "../../../types/SimpleModule";
import { type WidgetField } from "../../../widgets/WidgetField/WidgetField";
import { useGroupModuleColumns } from "../hooks/useGroupModuleColumns.hook";

interface GroupModulesTableProps {
  fields: WidgetField[];
  moduleRows: ModuleRow[];
  year: string;
}

interface GroupModulesTableRecord extends TableRecord, ModuleRow {}

export default function GroupModulesOverviewTable({
  fields,
  moduleRows,
  year
}: Readonly<GroupModulesTableProps>) {
  const periods = getPeriodService().getPeriods(year);
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    year
  );
  const { references: phases } = useReferences(ReferenceType.PHASE, year);

  const filteredFields = useMemo(() => {
    const orderKeys: Array<keyof WidgetField> = ["sequence", "name"];
    const visibleFields = fields.filter(({ visible }) => visible);
    const orderedFields = orderBy(visibleFields, orderKeys);
    return orderedFields.slice(0, 3);
  }, [fields]);

  const columns = useGroupModuleColumns<GroupModulesTableRecord>({
    fields: filteredFields,
    renderCode: (record) => <ModuleLink module={record.module} />,
    renderName: (record) => getText(record.module?.data.names),
    renderColumn: (field, record) => (
      <ModuleCell
        row={record}
        field={field}
        year={year}
        phases={phases}
        timeBlocks={timeBlocks}
      />
    )
  });

  const dataSource = useMemo((): GroupModulesTableRecord[] => {
    const sortedModuleRows = sortModules(
      moduleRows,
      phases,
      periods,
      timeBlocks
    );

    return sortedModuleRows
      .filter((moduleRow) => moduleRow.module?.data)
      .map((moduleRow) => ({
        ...moduleRow,
        key: getDefaultCode(moduleRow.module!.data)
      }));
  }, [moduleRows, periods, phases, timeBlocks]);

  return (
    <Table<GroupModulesTableRecord>
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rootClassName="table--structure"
      rowHoverable={true}
      size={"small"}
    />
  );
}
