import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import { useSpring, animated } from "@react-spring/web";
import { ThemeWrapper } from "@timeedit/ui-components";
import Loader from "@components/loader.component";
import useGlobalLoader from "@hooks/globalLoader.hook";

interface GlobalLoaderContextProps {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  transitionEnded: boolean;
}

export const GlobalLoaderContext = createContext<GlobalLoaderContextProps>(
  {} as GlobalLoaderContextProps
);

export default function GlobalLoaderProvider({ children }: PropsWithChildren) {
  const { isLoading, setTransitionEnded } = useGlobalLoader();
  const [renderLoader, setRenderLoader] = useState(false);

  const [fadeInLoaderStyle, fadeInLoader] = useSpring(() => ({
    from: { opacity: 0 }
  }));

  // show loader if 400ms passed
  const showLoaderTimerRef = useRef<number>();
  useEffect(() => {
    window.clearTimeout(showLoaderTimerRef.current);
    if (!isLoading) return;
    showLoaderTimerRef.current = window.setTimeout(() => {
      setRenderLoader(true);
    }, 400);

    return () => {
      window.clearTimeout(showLoaderTimerRef.current);
    };
  }, [isLoading]);

  // Animate the loader in
  const [renderChildren, setRenderChildren] = useState(!isLoading);
  useEffect(() => {
    if (!renderLoader) return;

    fadeInLoader.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      onResolve: () => {
        setRenderChildren(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderLoader]);

  const hideLoaderTimerRef = useRef<number>();
  useEffect(() => {
    window.clearTimeout(hideLoaderTimerRef.current);

    if (isLoading) return;
    if (!renderLoader) {
      setRenderChildren(true);
      setTransitionEnded(true);
      return;
    }

    // keep loader visible for at least 1s
    hideLoaderTimerRef.current = window.setTimeout(() => {
      fadeInLoader.start({
        from: { opacity: 1 },
        to: { opacity: 0 },
        onStart: () => {
          setTransitionEnded(true);
        },
        onResolve: () => {
          setRenderLoader(false);
        }
      });
    }, 1000);

    return () => {
      window.clearTimeout(hideLoaderTimerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      {/* show loader if we take longer then 400ms */}
      {renderLoader && (
        <animated.div
          className={`
            sg-fixed sg-z-[2147483647] sg-w-full sg-h-full sg-top-0 sg-left-0 sg-bg-white
            sg-flex sg-items-center sg-justify-center
          `}
          style={fadeInLoaderStyle}
        >
          <ThemeWrapper>
            <Loader />
          </ThemeWrapper>
        </animated.div>
      )}

      {/* pre-render as soon as the data is there. This should provide a smoother transition as long as the content renders within 1 second */}

      <div
        className={`
          sg-w-full sg-h-full
          ${!renderChildren ? "sg-hidden" : ""}
        `}
      >
        {children}
      </div>
    </>
  );
}
