import { isEmpty } from "lodash";
import { useReferences } from "../../../references/useReferences";
import type Value from "../../../types/canonical/Value";
import { type WidgetType } from "../../types/WidgetType";
import { getWidgetFieldValue } from "../../utils/WidgetUtils";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  values: Value[] | undefined;
  year: string;
  hideHeader: boolean;
  isTitle: boolean;
};

export function CustomItem({
  field,
  type,
  values = [],
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references } = useReferences(field.referenceType, year);
  const value = getWidgetFieldValue(field, values, references);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(value)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => <div>{value}</div>}
    </FieldSection>
  );
}
