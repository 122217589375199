import { groupBy, isEmpty } from "lodash";
import Filter from "./Filter";

class FilterService {
  private filters: { [key: string]: Filter[] } = {};

  public loadAllFilters = async () => {
    const filters = await Filter.page({
      size: 9999,
      sort: ["sequence,ASC", "name,ASC"]
    });

    this.filters = groupBy(filters.content, "entityType");
  };

  public getFilters = (type: string): Filter[] => {
    return isEmpty(this.filters) ? [] : this.filters[type];
  };

  public getVisibleFilters = (type: string): Filter[] => {
    const filters = instance.getFilters(type);

    if (isEmpty(filters)) {
      return [];
    }

    return filters.filter((filter) => filter.visible);
  };
}

const instance = new FilterService();

export function getFilterService(): FilterService {
  return instance;
}
