import dayjs, { type Dayjs } from "dayjs";
import { type FieldValidator } from "final-form";
import { isEmpty } from "lodash";
import i18n from "../i18n";

export function isDateBefore(maxDate: Dayjs): FieldValidator<Date> {
  return (value: Date): string | undefined => {
    if (dayjs(value).isBefore(maxDate)) {
      return undefined;
    }

    return i18n.t("validation:DATE_AFTER_MAX", {
      maxDate: maxDate.toLocaleString()
    });
  };
}

export function matchesRegex(
  regex: RegExp,
  name: string,
  example: string
): FieldValidator<string> {
  return (value: string): string | undefined => {
    if (!value || isEmpty(value) || regex.test(value)) {
      return undefined;
    }

    return i18n.t("validation:VALUE_DOES_NOT_MATCH_PATTERN", {
      name,
      example
    });
  };
}
