import { Col } from "reactstrap";
import { getText } from "../../../i18n/LocalizedText";
import { getVisibleSortedWidgetFields } from "../../utils/WidgetUtils";
import { WidgetItem } from "../../WidgetItem/WidgetItem";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";

interface SidebarWidgetProps extends WidgetRenderVariantProps {
  expanded: boolean;
  expandable: boolean;
}

export function SidebarWidget({
  widget,
  product,
  expanded,
  expandable
}: Readonly<SidebarWidgetProps>) {
  return (
    <Col className="details__content">
      {!widget.hideHeader && (
        <Col className="details__content__header">
          {getText(widget.labels, widget.name)}
        </Col>
      )}
      <Col className="details__content__container">
        {getVisibleSortedWidgetFields(widget).map((field) => (
          <WidgetItem
            key={`field-${field.id}`}
            field={field}
            type={widget.type}
            hideHeader={widget.hideHeader}
            product={product}
            expanded={expanded}
            expandable={expandable}
            isTitle={false}
          />
        ))}
      </Col>
    </Col>
  );
}
