import { get, isEmpty } from "lodash";
import { getProperties, matchFilter } from "../../../filters/Filters";
import { replaceVariables } from "../../../i18n/Interpolation";
import type Reference from "../../../references/Reference";
import { displayName, ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import type MethodSchema from "../../../types/canonical/MethodSchema";
import { WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  methods: MethodSchema[];
  year: string;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function MethodsItem({
  field,
  type,
  methods,
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references } = useReferences(ReferenceType.METHOD_TYPE, year);

  const names = methods
    .filter((schema) => {
      const properties = getProperties(schema.method);
      return matchFilter(field.filter, properties);
    })
    .map((schema) => describe(schema, field, references))
    .filter((name) => !!name)
    .sort();

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(names)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <ul className={type === WidgetType.SIDEBAR ? "list-unstyled" : ""}>
          {names.map((name, index) => (
            <li key={`method-${index}`}>{name}</li>
          ))}
        </ul>
      )}
    </FieldSection>
  );
}

function describe(
  schema: MethodSchema,
  field: WidgetField,
  references: Reference[]
) {
  const typeId = schema.method?.type;
  const type = displayName(typeId, references, typeId) || "";

  if (field.format) {
    const variables = {
      type,
      credits: `${get(schema.method, "credits", "")}`,
      contactHours: `${get(schema.method, "contactHours", "")}`
    };

    return replaceVariables(field.format, variables);
  }
  return type;
}
