import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components";
import type AcademicYear from "../../../types/AcademicYear";
import YearDropdown from "./YearDropdown";

interface ProductDetailYearDropdownProps {
  productYearsPromise: (id: number) => Promise<AcademicYear[]>;
  productId?: number;
  yearExternalId: string;
  onChange: (name: string, value: string) => void | undefined;
}

export default function ProductDetailYearDropdown({
  productYearsPromise,
  productId,
  yearExternalId,
  onChange
}: Readonly<ProductDetailYearDropdownProps>) {
  const queryState = useQuery({
    queryKey: ["productYears", productId],
    queryFn: () => {
      if (productId === undefined) {
        return Promise.resolve([]);
      }

      return productYearsPromise(productId);
    },
    enabled: productId !== undefined
  });

  return (
    <Loader state={queryState}>
      {(years) => (
        <YearDropdown
          externalId={yearExternalId}
          initYears={years}
          className="mb-4 d-inline"
          onChange={onChange}
        />
      )}
    </Loader>
  );
}
