import { chain, find, isEmpty } from "lodash";
import type Reference from "../../references/Reference";
import { displayName } from "../../references/Reference";
import type Value from "../../types/canonical/Value";
import { type Widget } from "../types/Widget";
import { type WidgetField } from "../WidgetField/WidgetField";

const getVisibleSortedWidgetFields = (widget: Widget) => {
  return chain(widget.fields || [])
    .filter("visible")
    .sortBy(["sequence", "name", "id"])
    .value();
};

const getWidgetFieldValue = (
  field: WidgetField,
  values: Value[],
  references: Reference[]
) => {
  const value = find(values, { name: field.name });
  if (!value) {
    return "";
  }

  if (!isEmpty(value.description)) {
    return value.description;
  }

  return translateWidgetFieldValues(value.values, references);
};

const translateWidgetFieldValues = (values: any[], references: Reference[]) => {
  return values
    .map((value) => {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }

      return displayName(value, references, value);
    })
    .sort()
    .join(", ");
};

export { getVisibleSortedWidgetFields, getWidgetFieldValue };
