import { useCallback, useEffect, useState } from "react";
import { useClearErrors } from "@42.nl/react-error-store";
import { Button, JarbInput, JarbSelect, SubmitButton } from "@42.nl/ui";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useTranslation } from "react-i18next";
import CheckboxField from "../components/Checkbox/CheckboxField";
import CollapsibleCheckboxMultiSelectField from "../components/CollapsibleCheckboxMultiSelect/CollapsibleCheckboxMultiSelectField";
import { filterValidator } from "../filters/Filters";
import { getText, type LocalizedText } from "../i18n/LocalizedText";
import TextInput from "../i18n/TextInput";
import { PRODUCT_TYPES, type ProductType } from "../types/Product";
import { type Widget } from "../widgets/types/Widget";
import WidgetSelect from "../widgets/WidgetSelect/WidgetSelect";
import { useMainTabs } from "./context/hooks/useMainTabs";
import type Tab from "./types/Tab";
import { tabModesValidator } from "./utils/tabUtils";

export type TabFormData = {
  id?: number;
  entityType?: ProductType;
  name?: string;
  sequence?: number;
  labels: LocalizedText[];
  grouping?: string;
  filter?: string;
  visible?: boolean;
  parentTabs?: Tab[];
  widgets?: Widget[];
};

type Props = {
  initialValues: TabFormData;
  onCancel: () => void;
  onSubmit: (data: TabFormData) => void;
};

export default function TabForm(props: Readonly<Props>) {
  const { t } = useTranslation(["tab", "translation"]);
  const { onCancel, onSubmit, initialValues } = props;

  const parentTabs = useMainTabs();
  const [parentTabOptions, setParentTabOptions] = useState<Tab[]>([]);

  const filterParentTabs = useCallback(
    (entityType: ProductType) =>
      parentTabs.filter(
        (tab) => tab.entityType === entityType && initialValues?.id !== tab.id
      ),
    [parentTabs, initialValues]
  );

  useEffect(() => {
    if (initialValues.entityType) {
      const filteredParentTabs = filterParentTabs(initialValues.entityType);
      setParentTabOptions(filteredParentTabs);
    }
  }, [initialValues.entityType, filterParentTabs]);

  // Clear previous back-end errors if this form was shown before
  useClearErrors();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <JarbInput
            id="name"
            name="name"
            type="text"
            jarb={{
              validator: "Tab.name",
              label: t("tab:COLUMNS.NAME")
            }}
            label={t("tab:COLUMNS.NAME")}
          />

          <JarbSelect
            id="entityType"
            name="entityType"
            label={t("tab:COLUMNS.ENTITY_TYPE")}
            placeholder={t("tab:ENTITY_TYPE_PLACEHOLDER")}
            options={PRODUCT_TYPES}
            labelForOption={(type) => type}
            jarb={{
              validator: "Tab.entityType",
              label: t("tab:COLUMNS.ENTITY_TYPE")
            }}
          />

          <OnChange name="entityType">
            {(entityType: ProductType) => {
              setParentTabOptions(filterParentTabs(entityType));
            }}
          </OnChange>

          <CollapsibleCheckboxMultiSelectField<Tab>
            name="parentTabs"
            label={t("tab:PARENT_PLACEHOLDER")}
            className="mb-3"
            options={parentTabOptions}
            labelForOption={(tab) =>
              getText(tab.labels, tab.name) + ` (${tab.name})`
            }
            keyForOption={(tab) => tab.id.toString()}
          />

          <WidgetSelect id="widgets" entityType={initialValues.entityType} />

          <TextInput id="labels" label={t("tab:COLUMNS.LABEL")} />

          <JarbInput
            id="sequence"
            name="sequence"
            type="number"
            jarb={{
              validator: "Tab.sequence",
              label: t("tab:COLUMNS.SEQUENCE")
            }}
            label={t("tab:COLUMNS.SEQUENCE")}
          />

          <JarbInput
            id="filter"
            name="filter"
            type="text"
            jarb={{
              validator: "Tab.filter",
              label: t("tab:COLUMNS.FILTER")
            }}
            label={t("tab:COLUMNS.FILTER")}
            validators={[
              filterValidator(t("tab:COLUMNS.FILTER"), "type=MA,BA")
            ]}
          />

          <JarbInput
            id="modes"
            name="modes"
            type="text"
            jarb={{
              validator: "Tab.modes",
              label: t("tab:COLUMNS.MODES")
            }}
            placeholder="list,table"
            label={t("tab:COLUMNS.MODES")}
            validators={[
              tabModesValidator(t("tab:COLUMNS.MODES"), "list,table")
            ]}
          />

          <CheckboxField
            name="visible"
            label={t("tab:COLUMNS.VISIBLE")}
            className="mb-3"
          />

          <div className="d-flex justify-content-end gap-2">
            <Button onClick={onCancel} color="secondary">
              {t("translation:CANCEL")}
            </Button>
            <SubmitButton inProgress={submitting}>
              {t("translation:SUBMIT_BUTTON")}
            </SubmitButton>
          </div>
        </form>
      )}
    />
  );
}
