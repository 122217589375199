import classNames from "classnames";
import { Link } from "react-router-dom";
import { getText } from "../i18n/LocalizedText";
import { type Product, getProductUrl } from "../types/Product";

interface ProductLinkProps {
  product?: Product;
  className?: string;
}

export function ProductLink({
  product,
  className
}: Readonly<ProductLinkProps>) {
  if (!product) {
    return null;
  }

  const productUrl = getProductUrl(product); // Objectives don't have a URL

  function onClick(event: React.MouseEvent) {
    event.stopPropagation();
  }

  return (
    <span className={classNames(className)}>
      {productUrl && (
        <Link
          onClick={onClick}
          to={productUrl}
          className="me-2"
          target="_blank"
        >
          {product.data.code ?? product.data.id ?? "?"}
        </Link>
      )}
      <span>{getText(product.data.names)}</span>
    </span>
  );
}
