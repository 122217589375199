import { Link } from "react-router-dom";

type DetailsLinkProps = {
  linkToDetail: string;
  seeMoreText: string;
};

export const QuickviewDetailsLink = ({
  linkToDetail,
  seeMoreText
}: Readonly<DetailsLinkProps>) => {
  return (
    <div className="mt-1">
      <Link to={linkToDetail}>{seeMoreText}</Link>
    </div>
  );
};
