import { memo } from "react";
import { Tooltip } from "antd";
import { getText } from "../../../../i18n/LocalizedText";
import type Subject from "../../../../types/canonical/Subject";

interface SubjectHeaderCellProps {
  subject: Subject;
}

const MatrixWidgetTableSubjectHeaderCell = memo(
  ({ subject }: SubjectHeaderCellProps) => {
    const title = getText(subject.reference.names, subject.reference.code);
    const descriptions = getText(subject.descriptions, title);
    const tooltip = getTooltip(title, descriptions);

    return (
      <div className="matrix-widget__cell--subject--title__contents">
        <Tooltip title={tooltip} aria-label={tooltip}>
          {title}
        </Tooltip>
      </div>
    );
  }
);

function getTooltip(title: string, descriptions: string): string {
  if (title === descriptions) {
    return title;
  }
  return `${title}: ${descriptions}`;
}

MatrixWidgetTableSubjectHeaderCell.displayName =
  "MatrixWidgetTableSubjectHeaderCell";

export default MatrixWidgetTableSubjectHeaderCell;
