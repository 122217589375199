import { type QueryClient } from "@tanstack/react-query";
import { router } from "react-query-kit";
import { invalidateQueryCaches } from "../../../core/api/invalidateQueryCaches";
import {
  getRequest,
  buildBaseUrl,
  getPagedRequest,
  deleteRequest,
  saveRequest
} from "../../../core/api/requests";
import { type TabPageQueryParams } from "../hooks/useTabPageQueryParams.hook";
import { type TabFormData } from "../TabForm";
import type Tab from "../types/Tab";

const baseUrl = buildBaseUrl("/tabs");

const tabApi = router("tab", {
  list: router.query({
    fetcher: (queryParams: TabPageQueryParams) =>
      getRequest<Tab[]>(baseUrl, queryParams)
  }),

  page: router.query({
    fetcher: (queryParams: TabPageQueryParams) =>
      getPagedRequest<Tab>(baseUrl, queryParams)
  }),

  remove: router.mutation({
    mutationFn: (data: TabFormData) => deleteRequest<Tab>(baseUrl, data)
  }),

  save: router.mutation({
    mutationFn: (data: TabFormData) => saveRequest<Tab>(baseUrl, data)
  })
});

const invalidateTabCaches = async (queryClient: QueryClient) => {
  await invalidateQueryCaches(queryClient, tabApi);
};

export { tabApi, invalidateTabCaches };
