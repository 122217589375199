import { useMemo } from "react";
import type Tab from "../../types/Tab";
import { tabIsParent } from "../../utils/parentTabUtils";
import { useActiveMainTab } from "./useActiveMainTab";
import { useTabsContext } from "./useTabsContext";

export function useSubTabs(): Tab[] {
  const { sortedTabs = [] } = useTabsContext();
  const activeMainTab = useActiveMainTab();

  return useMemo(() => {
    if (!activeMainTab) {
      return [];
    }
    return sortedTabs.filter((tab) => {
      return (
        tab.entityType === activeMainTab.entityType &&
        !tabIsParent(tab) &&
        tab.parentTabs?.some(
          (parentTab) => parentTab.id === activeMainTab.id
        ) &&
        tab.id !== activeMainTab.id
      );
    });
  }, [sortedTabs, activeMainTab]);
}
