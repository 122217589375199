import { useMemo } from "react";
import { get } from "lodash";
import { type ParsedFilter } from "../../../../../filters/hooks/useParseFilter";
import type Subject from "../../../../../types/canonical/Subject";
import { type Product } from "../../../../../types/Product";
import { getProductDataSubjects } from "../subjectUtils";

interface UseProductSubjectsProps {
  /**
   * Filter to apply to the product subjects, e.g.
   * reference.type=Lo&reference.subType=V
   * { "reference.subType": ["V"], "reference.type": ["Lo"] }
   */
  widgetFilter: ParsedFilter;
  product: Product;
}

const useProductSubjects = ({
  widgetFilter,
  product
}: UseProductSubjectsProps): Subject[] => {
  return useMemo(() => {
    if (!product) {
      return [];
    }

    const productDataSubjects = getProductDataSubjects(product.data);

    if (!Object.keys(widgetFilter).length) {
      return productDataSubjects;
    }

    return productDataSubjects.filter((subject) =>
      Object.entries(widgetFilter).every(([key, values]) => {
        const value = get(subject, key);
        return value && values.includes(value);
      })
    );
  }, [product, widgetFilter]);
};

export default useProductSubjects;
