import type Tab from "../types/Tab";

export function getActiveSubTab(
  sortedSubTabs: Tab[],
  name?: string
): Tab | undefined {
  if (!sortedSubTabs) return undefined;

  if (name) {
    const tabInUrl = sortedSubTabs.find((tab) => tab.name === name);
    if (tabInUrl) return tabInUrl;
  }

  const fallbackTab = sortedSubTabs[0];
  return fallbackTab || undefined;
}
