import { Button, type Color } from "@42.nl/ui";
import { isAdmin } from "../../authentication/CurrentUser";
import { useAuthentication } from "../../authentication/useAuthentication";

type Props = {
  type?: "button" | "submit";
  label: string;
  className?: string;
  disabled?: boolean;
  color?: Color;
  onClick?: () => void;
};

export function AdminButton({
  type = "button",
  label,
  className,
  disabled,
  color = "secondary",
  onClick
}: Readonly<Props>) {
  const auth = useAuthentication();
  if (!isAdmin(auth.currentUser)) {
    return <></>;
  }

  return (
    <Button
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
      color={color}
    >
      {label}
    </Button>
  );
}
