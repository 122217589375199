import { getVisibleSortedWidgetFields } from "../../utils/WidgetUtils";
import { WidgetItem } from "../../WidgetItem/WidgetItem";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";

interface ContainerWidgetProps extends WidgetRenderVariantProps {
  expanded: boolean;
  expandable: boolean;
}

export function ContainerWidget({
  widget,
  product,
  expanded,
  expandable
}: Readonly<ContainerWidgetProps>) {
  return (
    <>
      {getVisibleSortedWidgetFields(widget).map((field) => (
        <WidgetItem
          key={`field-${field.id}`}
          field={field}
          type={widget.type}
          hideHeader={widget.hideHeader}
          product={product}
          expanded={expanded}
          expandable={expandable}
          isTitle={false}
        />
      ))}
    </>
  );
}
