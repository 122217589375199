import { useMemo } from "react";
import useWidgetColumns from "../../components/Table/hooks/useWidgetColumns.hook";
import { type TableRecord, Table } from "../../components/Table/Table";
import { type Product } from "../../types/Product";
import { usePrimaryWidgetFields } from "../../widgets/hooks/usePrimaryWidgetFields.hook";
import { type Widget } from "../../widgets/types/Widget";

export interface ProductTableRecord extends TableRecord {
  product?: Product;
}

type ProductOverviewTableProps = {
  products: Product[];
  widgets: Widget[];
  onProductClick: (product: Product) => void; // Should be replaced with a link that can be opened in a new tab
};

export default function ProductOverviewTable({
  products,
  widgets,
  onProductClick
}: Readonly<ProductOverviewTableProps>) {
  const primaryWidgetFields = usePrimaryWidgetFields(widgets);
  const columns = useWidgetColumns<ProductTableRecord>({
    fields: primaryWidgetFields,
    enableStickyColumns: true
  });

  function createDataSource(): ProductTableRecord[] {
    return products.map((product) => ({
      key: product.id,
      product
    }));
  }

  const dataSource = useMemo(createDataSource, [products]);

  return (
    <div className="product__overview--table product-panel p-0">
      <Table<ProductTableRecord>
        columns={columns}
        dataSource={dataSource}
        scrollable
        pagination={false}
        rowHoverable
        onRow={(record) => ({
          onClick: () => {
            const product = record.product;
            if (product) {
              onProductClick(product);
            }
          }
        })}
        size={"small"}
      />
    </div>
  );
}
