import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../../InfoTooltip/InfoTooltip";

type Props = {
  label?: string;
  tooltip?: string;
  value?: string | number | bigint | boolean | undefined;
  className?: string;
  hideLabel?: boolean;
  children?: React.ReactNode;
};

export function QuickviewItem({
  label,
  tooltip,
  value,
  className,
  hideLabel,
  children
}: Readonly<Props>) {
  const { t } = useTranslation(["translation"]);

  let displayValue: string = t("translation:NO_DATA");
  let isBoolean = false;

  if (value !== undefined) {
    if (typeof value === "string" && value !== "") {
      displayValue = value;
    } else if (typeof value === "number") {
      displayValue = String(value);
    } else if (typeof value === "boolean") {
      isBoolean = true;
    }
  }

  if (isBoolean) return <></>;

  return (
    <div
      className={classNames("quickview__extra__item", className, {
        "mb-2": !hideLabel
      })}
    >
      {!hideLabel && label && (
        <div className="quickview__extra__item__label me-1">{label}:</div>
      )}
      <div className="quickview__extra__item__value">
        {children || displayValue}
      </div>
      {!hideLabel && <InfoTooltip tooltip={tooltip} />}
    </div>
  );
}
