import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Parameter, { loadParameters } from "./Parameter";

type Props = {
  children: React.ReactNode;
  onLoad?: (() => void)[];
};

export function ParameterProvider({ children, onLoad }: Readonly<Props>) {
  const [parameters, setParameters] = useState<Parameter[]>();

  const state = useQuery({
    queryKey: [Parameter.QUERY_KEY],
    queryFn: async () => {
      try {
        return await loadParameters();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error(
          "Unable to load the parameters from the provider on initial load. \n Is the backend running?"
        );
      }
    }
  });

  useEffect(() => {
    if (state.data) {
      setParameters(state.data);
      if (onLoad && onLoad.length > 0) {
        onLoad.forEach((callback) => {
          callback();
        });
      }
    }
  }, [state.data, onLoad]);

  if (!parameters) return <></>;

  return children;
}
