import { get, makeResource, post } from "@42.nl/spring-connect";
import { type QueryParams, search } from "../filters/Search";
import type AcademicYear from "./AcademicYear";
import type Study from "./canonical/Study";
import { type Product, ProductTypeEnum } from "./Product";
import { type Rule } from "./Rule";

const baseUrl = "/api/study";

export default class SimpleStudy
  extends makeResource<SimpleStudy>(baseUrl)
  implements Product
{
  id!: number;
  data!: Study;
  productType!: string;

  static years(id: number): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${id}/years`);
  }

  static async import(study: SimpleStudy) {
    return post(`${baseUrl}/${study.id}/import`, null);
  }

  static rules(product: Product): Promise<Rule[]> {
    return get(`${baseUrl}/${product.id}/rules`);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleStudy>(ProductTypeEnum.STUDY, baseUrl, queryParams);
  }
}
