import { Label } from "reactstrap";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";

type Props = {
  name: string;
  label?: string;
  tooltip?: string;
  className?: string;
};

export function FilterLabel({
  name,
  label,
  tooltip,
  className
}: Readonly<Props>) {
  return (
    <Label for={name} className={className}>
      {label}
      <InfoTooltip tooltip={tooltip} />
    </Label>
  );
}
