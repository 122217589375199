import { type Url } from "@42.nl/react-url";

export const LOGIN_URL = "/login";
export const SUPPORT_URL = "/support";
export const TOKEN_URL = "/token";
export const FORBIDDEN_URL = "/forbidden";

export function toLogin(): Url {
  return LOGIN_URL;
}
