import { useState } from "react";
import { get } from "@42.nl/spring-connect";
import { useQuery } from "@tanstack/react-query";

export type Environment = {
  name: string;
  alert: boolean;
};

export function loadEnvironment(): Promise<Environment> {
  return get("/api/actuator/environment");
}

export default function EnvBanner() {
  const { data } = useQuery({
    queryKey: ["environment"],
    queryFn: loadEnvironment
  });

  const [visible, setVisible] = useState(true);

  if (!data) {
    return <></>;
  }

  const { name, alert } = data;

  if (!alert || !visible) {
    return <></>;
  }

  return (
    <div className="EnvBanner" onClick={() => setVisible(false)}>
      <span>{name}</span>
    </div>
  );
}
