import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

type Props = {
  labelKey: string;
  urlKey: string;
  namespace: string;
  children?: React.ReactNode;
};

export default function LinkLabel({
  labelKey,
  urlKey,
  namespace,
  children
}: Readonly<Props>) {
  const { t } = useTranslation(namespace);

  const label = t(labelKey);
  const url = t(urlKey);

  if (isInvalidLabel(label, labelKey) || isInvalidLabel(url, urlKey)) {
    return <></>;
  }

  return (
    <a href={url} aria-label={label}>
      {children || label}
    </a>
  );
}

function isInvalidLabel(label: string, key: string) {
  return isEmpty(label) || label === key;
}
