import i18n from "../../core/i18n";

// Not named "Text" because it conflicts with built-in type
export type LocalizedText = {
  language?: string;
  value?: string;
};

export function getText(
  texts?: LocalizedText[] | undefined,
  fallback: string = ""
): string {
  if (texts === undefined) {
    return fallback;
  }

  const language = i18n.language.toUpperCase();
  let value = texts.find((text) => text.language === language)?.value;
  if (!value) {
    value =
      texts.map((text) => text.value).find((value_) => !!value_) || fallback;
  }
  return value;
}
