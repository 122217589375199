import { JarbSelect } from "@42.nl/ui";
import { getLanguages } from "../../core/i18n";

type Props = {
  name: string;
  label: string;
  validator: string;
};

export default function LanguageSelect({
  name,
  label,
  validator
}: Readonly<Props>) {
  const languages = getLanguages().map((language) =>
    language.toLocaleUpperCase()
  );

  return (
    <JarbSelect
      id={name}
      name={name}
      label={label}
      options={languages}
      labelForOption={(language) => language}
      allowNull={true}
      jarb={{
        validator: validator,
        label: label
      }}
    />
  );
}
