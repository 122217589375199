import { type Key } from "react";
import { type ProductTree } from "../types/ProductTree";
import type { ProductTreeNode } from "../types/ProductTreeNode";

const findProductNodeRecursively = (
  productTree: ProductTree,
  productCompositeUid: Key
): ProductTreeNode | undefined => {
  const searchTree = (nodes: ProductTree): ProductTreeNode | undefined => {
    for (const node of nodes) {
      if (node.key === productCompositeUid) return node;
      if (node.children) {
        const foundNode = searchTree(node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  };
  return searchTree(productTree);
};

export default findProductNodeRecursively;
