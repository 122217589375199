import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { Compact } from "../../../components";
import { getDescriptionType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import { getDescriptionText } from "../../../types/canonical/Description";
import { type Product } from "../../../types/Product";
import { type WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  expanded: boolean;
  expandable: boolean;
  hideHeader: boolean;
  isTitle: boolean;
};

export function DescriptionItem({
  field,
  type,
  product,
  expanded,
  expandable,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { t } = useTranslation("translation");
  const { references } = useReferences(
    getDescriptionType(product.productType),
    product.data.year.id
  );

  const descriptions = product.data.descriptions || [];
  const found = descriptions.filter(
    (description) => description.type?.id === field.name
  );

  const valid = found.filter((description) =>
    references.find((ref) => ref.externalId === description.type?.id)
  );

  if (isEmpty(valid)) {
    return <></>;
  }

  const descriptionText = getDescriptionText(
    found,
    t("NO_CURRENT_LANGUAGE_INFO")
  );

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(descriptionText)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <div style={{ fontSize: "1.5rem" }}>
          <Compact expanded={expanded} expandable={expandable}>
            <div className="details__content__main__section__value">
              {descriptionText}
            </div>
          </Compact>
        </div>
      )}
    </FieldSection>
  );
}
