import { type Product } from "../types/Product";
import { moduleDetailUrl } from "./ModuleDetail/ModuleDetail";

type ModuleLinkProps = {
  module?: Product;
};

export default function ModuleLink({ module }: Readonly<ModuleLinkProps>) {
  if (!module?.data) {
    return <></>;
  }

  return <a href={moduleDetailUrl(module.data)}>{module.data.code}</a>;
}
