import { useMemo } from "react";
import { WidgetType } from "../../types/WidgetType";
import { getVisibleSortedWidgetFields } from "../../utils/WidgetUtils";
import { WidgetItem } from "../../WidgetItem/WidgetItem";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";

export function PrimaryWidget({
  widget,
  product
}: Readonly<WidgetRenderVariantProps>) {
  const { firstField, otherFields } = useMemo(() => {
    const sortedFields = getVisibleSortedWidgetFields(widget);
    if (!sortedFields.length) {
      return { firstField: null, otherFields: [] };
    }
    return {
      firstField: sortedFields[0],
      otherFields: sortedFields.slice(1)
    };
  }, [widget]);

  if (!firstField) {
    return <></>;
  }

  return (
    <>
      <WidgetItem
        key={`field-${firstField.id}`}
        field={firstField}
        type={WidgetType.PRIMARY}
        hideHeader={false}
        product={product}
        expanded={false}
        expandable={false}
        isTitle={true}
      />

      <div className="d-flex mt-2 column-gap--2_4rem flex-wrap">
        {otherFields.map((field) => (
          <WidgetItem
            key={`field-${field.id}`}
            field={field}
            type={WidgetType.PRIMARY}
            hideHeader={false}
            product={product}
            expanded={false}
            expandable={false}
            isTitle={false}
          />
        ))}
      </div>
    </>
  );
}
