import { useMemo } from "react";
import { groupBy } from "lodash";
import { type Widget } from "../types/Widget";
import { WidgetType } from "../types/WidgetType";

export type GroupedWidgets = {
  detail: Widget[];
  matrix: Widget[];
  primary: Widget[];
  summary: Widget[];
};

export function useWidgetGroups(widgets: Widget[]): GroupedWidgets {
  return useMemo(() => {
    const groups = groupBy(widgets, (widget) => {
      switch (widget.type) {
        case WidgetType.MATRIX:
          return "matrix";
        case WidgetType.PRIMARY:
          return "primary";
        case WidgetType.SIDEBAR:
          return "detail";
        default:
          return "summary";
      }
    }) as Partial<GroupedWidgets>;

    return {
      detail: groups.detail || [],
      matrix: groups.matrix || [],
      primary: groups.primary || [],
      summary: groups.summary || []
    };
  }, [widgets]);
}
