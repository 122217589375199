import { Checkbox } from "antd";
import { Field } from "react-final-form";

interface CheckboxFieldProps {
  name: string;
  label: string;
  className?: string;
}

const CheckboxField = ({
  name,
  label,
  className
}: Readonly<CheckboxFieldProps>) => {
  return (
    <Field<boolean>
      name={name}
      type="checkbox"
      className={className}
      render={({ input }) => (
        <Checkbox {...input} id={`${name}-checkbox`} className="form__checkbox">
          {label}
        </Checkbox>
      )}
    />
  );
};

export default CheckboxField;
