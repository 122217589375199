import { Loader } from "../../../components";
import ModuleListItem from "../../../modules/ModuleOverview/ModuleListItem";
import { getPeriodService } from "../../../periods/PeriodService";
import { ReferenceType } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import { useMainTabs } from "../../../tabs/context/hooks/useMainTabs";
import { type Group } from "../../../types/canonical/Group";
import { sortModules } from "../../../types/SimpleModule";
import useGroupModules from "../hooks/useGroupModules.hook";

interface GroupModulesListProps {
  group: Group;
  year: string;
}

export function GroupModulesList({
  group,
  year
}: Readonly<GroupModulesListProps>) {
  const state = useGroupModules({ group });
  const periods = getPeriodService().getPeriods(year);
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    year
  );
  const { references: phases } = useReferences(ReferenceType.PHASE, year);

  const mainTabs = useMainTabs();
  const moduleTab = mainTabs.find((mainTab) => mainTab.entityType === "MODULE");

  return (
    <Loader state={state}>
      {(rows) => (
        <>
          {sortModules(rows, phases, periods, timeBlocks).map(
            (row, index) =>
              row.module !== undefined && (
                <ModuleListItem
                  key={index}
                  module={row.module}
                  widgets={moduleTab?.widgets || []}
                />
              )
          )}
        </>
      )}
    </Loader>
  );
}
