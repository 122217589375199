import { Button, ThemeWrapper } from "@timeedit/ui-components";
import { Card } from "antd";

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

export default function Error({
  error,
  resetErrorBoundary: reset
}: FallbackProps) {
  const { message } = error;
  return (
    <ThemeWrapper>
      <div className="sg-h-full sg-w-full sg-flex sg-flex-col sg-items-center sg-justify-center sg-bg-colorPrimaryBg sg-p-4">
        <Card
          title="Something went wrong!"
          type="inner"
          className="sg-min-w-[240px] sg-max-w-[500px] sg-shadow-sm"
        >
          <p>
            <b>Error:</b>
            <br />
            <span className="sg-text-te-colorError sg-whitespace-pre-line">
              {message}
            </span>
          </p>
          <div className="sg-mt-4 sg-flex sg-justify-end">
            <Button onClick={() => reset()} type="primary">
              Try again
            </Button>
          </div>
        </Card>
      </div>
    </ThemeWrapper>
  );
}
