/* istanbul ignore file */

/**
 * We make istanbul ignore this file because the lazy imports are not
 * tested. The actual components themselves are already being tested
 * in their own file, and we do not want to test React's lazy itself.
 */

import { lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "@components/wrappers/layout.wrapper";
import useGlobalLoader from "@hooks/globalLoader.hook";
import { ABOUT_URL } from "./About/links";
import { AdminRoute } from "./components/AdminRoute/AdminRoute";
import { FACULTY_DETAIL_URL } from "./faculties/FacultyDetail/FacultyDetail";
import { FACULTY_APP_URL } from "./faculties/FacultyLinks";
import { FILTER_APP_URL } from "./filters/links";
import { GROUP_DETAIL_URL } from "./groups/GroupDetail/GroupDetail";
import { IMPORT_APP_URL } from "./import/links";
import { JOB_APP_URL } from "./jobs/links";
import LabelApp from "./labels/LabelApp";
import { LABEL_APP_URL } from "./labels/links";
import { MODULE_DETAIL_URL } from "./modules/ModuleDetail/ModuleDetail";
import { PARAMETER_APP_URL } from "./parameters/links";
import { PERIOD_APP_URL } from "./periods/links";
import { QUALIFICATION_DETAIL_URL } from "./qualifications/QualificationDetail/QualificationDetail";
import { QUALIFICATION_APP_URL } from "./qualifications/QualificationLinks";
import { REFERENCE_APP_URL } from "./references/links";
import { STUDY_DETAIL_URL } from "./studies/StudyDetail/StudyDetail";
import { STUDY_APP_URL } from "./studies/StudyLinks";
import { TabsProvider } from "./tabs/context/TabsContext";
import TabApp from "./tabs/TabApp";
import { TAB_APP_URL } from "./tabs/utils/tabUrlUtils";
import { GROUP_APP_URL } from "./types/GroupLinks";
import { MODULE_APP_URL } from "./types/ModuleLinks";
import { WIDGET_APP_URL } from "./widgets/links";
import { YEAR_APP_URL } from "./years/links";
import { NO_YEARS_APP_URL } from "./years/NoYearsApp";
import { YearRoute } from "./years/YearRoute";

const About = lazy(
  () => import(/* webpackChunkName: "about" */ "./About/About")
);

const ModuleApp = lazy(
  () => import(/* webpackChunkName: "modules" */ "./modules/ModuleApp")
);

const ModuleDetailApp = lazy(
  () =>
    import(
      /* webpackChunkName: "moduleDetail" */ "./modules/ModuleDetail/ModuleDetail"
    )
);

const GroupApp = lazy(
  () => import(/* webpackChunkName: "groups" */ "./groups/GroupApp")
);

const GroupDetailApp = lazy(
  () =>
    import(
      /* webpackChunkName: "groupDetail" */ "./groups/GroupDetail/GroupDetail"
    )
);

const QualificationApp = lazy(
  () =>
    import(
      /* webpackChunkName: "studies" */ "./qualifications/QualificationApp"
    )
);

const QualificationDetailApp = lazy(
  () =>
    import(
      /* webpackChunkName: "studyDetail" */ "./qualifications/QualificationDetail/QualificationDetail"
    )
);

const StudyApp = lazy(
  () => import(/* webpackChunkName: "studies" */ "./studies/StudyApp")
);

const StudyDetailApp = lazy(
  () =>
    import(
      /* webpackChunkName: "studyDetail" */ "./studies/StudyDetail/StudyDetail"
    )
);

const FacultyApp = lazy(
  () =>
    import(
      /* webpackChunkName: "facultyDescription" */ "./faculties/FacultyApp"
    )
);

const FacultyDetailApp = lazy(
  () =>
    import(
      /* webpackChunkName: "studyDetail" */ "./faculties/FacultyDetail/FacultyDetail"
    )
);

const FilterApp = lazy(
  () => import(/* webpackChunkName: "filters" */ "./filters/FilterApp")
);

const ReferenceApp = lazy(
  () => import(/* webpackChunkName: "references" */ "./references/ReferenceApp")
);

const AuthenticationApp = lazy(
  () =>
    import(/* webpackChunkName: "users" */ "./authentication/AuthenticationApp")
);

const WidgetApp = lazy(
  () => import(/* webpackChunkName: "widgets" */ "./widgets/WidgetApp")
);

const ParameterApp = lazy(
  () => import(/* webpackChunkName: "parameters" */ "./parameters/ParameterApp")
);

const ImportApp = lazy(
  () => import(/* webpackChunkName: "import" */ "./import/ImportApp")
);

const YearApp = lazy(
  () => import(/* webpackChunkName: "years" */ "./years/YearApp")
);

const NoYearsApp = lazy(
  () => import(/* webpackChunkName: "noYears" */ "./years/NoYearsApp")
);

const PeriodApp = lazy(
  () => import(/* webpackChunkName: "periods" */ "./periods/PeriodApp")
);

const JobApp = lazy(
  () => import(/* webpackChunkName: "jobs" */ "./jobs/JobApp")
);

export default function App() {
  const { setIsLoading } = useGlobalLoader();

  // done loading, render page!
  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              path={QUALIFICATION_APP_URL}
              element={<YearRoute element={<QualificationApp />} />}
            />
            <Route
              path={QUALIFICATION_DETAIL_URL + "/*"}
              element={<YearRoute element={<QualificationDetailApp />} />}
            />

            <Route
              path={STUDY_APP_URL}
              element={<YearRoute element={<StudyApp />} />}
            />
            <Route
              path={STUDY_DETAIL_URL + "/*"}
              element={<YearRoute element={<StudyDetailApp />} />}
            />

            <Route
              path={GROUP_APP_URL}
              element={<YearRoute element={<GroupApp />} />}
            />
            <Route
              path={GROUP_DETAIL_URL + "/*"}
              element={<YearRoute element={<GroupDetailApp />} />}
            />

            <Route
              path={MODULE_APP_URL}
              element={<YearRoute element={<ModuleApp />} />}
            />
            <Route
              path={MODULE_DETAIL_URL + "/*"}
              element={<YearRoute element={<ModuleDetailApp />} />}
            />

            <Route
              path={FACULTY_APP_URL}
              element={<YearRoute element={<FacultyApp />} />}
            />
            <Route
              path={FACULTY_DETAIL_URL + "/*"}
              element={<YearRoute element={<FacultyDetailApp />} />}
            />

            <Route
              path={FILTER_APP_URL}
              element={<AdminRoute element={<FilterApp />} />}
            />

            <Route
              path={REFERENCE_APP_URL}
              element={<AdminRoute element={<ReferenceApp />} />}
            />

            <Route
              path={TAB_APP_URL}
              element={<AdminRoute element={<TabApp />} />}
            />

            <Route
              path={LABEL_APP_URL}
              element={<AdminRoute element={<LabelApp />} />}
            />

            <Route
              path={WIDGET_APP_URL}
              element={<AdminRoute element={<WidgetApp />} />}
            />

            <Route
              path={PARAMETER_APP_URL}
              element={<AdminRoute element={<ParameterApp />} />}
            />

            <Route
              path={IMPORT_APP_URL}
              element={<AdminRoute element={<ImportApp />} />}
            />

            <Route
              path={YEAR_APP_URL}
              element={<AdminRoute element={<YearApp />} />}
            />

            <Route
              path={NO_YEARS_APP_URL}
              element={<AdminRoute element={<NoYearsApp />} />}
            />

            <Route
              path={PERIOD_APP_URL}
              element={<AdminRoute element={<PeriodApp />} />}
            />

            <Route
              path={JOB_APP_URL}
              element={<AdminRoute element={<JobApp />} />}
            />

            <Route
              path={ABOUT_URL}
              element={<AdminRoute element={<About />} />}
            />
          </Route>
          <Route path="*" element={<AuthenticationApp />} />
        </Routes>
      </BrowserRouter>
    </TabsProvider>
  );
}
