import { authFetch } from "@42.nl/authentication";
import { makeResource } from "@42.nl/spring-connect";
import { lowerCase } from "lodash";

const baseUrl = "/api/parameters";

export default class Parameter extends makeResource<Parameter>(baseUrl) {
  id!: number;
  name!: string;
  value!: string;

  static readonly QUERY_KEY = "parameters";
  static readonly QUERY_KEY_PAGE = "parametersPage";
}

export async function loadParameters() {
  return authFetch(baseUrl, {})
    .then((response) => response.json())
    .then((parameters: Parameter[]) => {
      parameters.forEach((parameter) => {
        const key = getKey(parameter.name);
        if (!isEmpty(parameter.value)) {
          sessionStorage.setItem(key, parameter.value);
        } else {
          sessionStorage.removeItem(key);
        }
      });
      return parameters;
    });
}

function getKey(name: string) {
  return `parameter-${name}`;
}

function isEmpty(value: string | null | undefined) {
  return value === null || value === undefined || value === "";
}

export function getParameter(name: string, defaultValue: string) {
  const value = sessionStorage.getItem(getKey(name));
  return value || defaultValue;
}

export function getBooleanParameter(name: string, defaultValue: boolean) {
  const value = sessionStorage.getItem(getKey(name));
  if (isEmpty(value)) {
    return defaultValue;
  }
  return lowerCase(value || "") === "true";
}

export function getListParameter(name: string, defaultValue: []) {
  const values = sessionStorage.getItem(getKey(name));
  if (isEmpty(values)) {
    return defaultValue;
  }
  return (values || "")
    .split(",")
    .map((val) => val.trim())
    .filter((val) => !isEmpty(val));
}
