import { partition } from "lodash";
import type Reference from "../references/Reference";
import { displayName } from "../references/Reference";
import { getCredits } from "../types/canonical/Credits";
import { getPeriodsDisplayName } from "../types/canonical/Offering";
import { type ModuleRow } from "../types/SimpleModule";
import { WidgetType } from "../widgets/types/WidgetType";
import { type WidgetField } from "../widgets/WidgetField/WidgetField";
import { CustomItem } from "../widgets/WidgetItem/components/CustomItem";

type ModuleCellProps = {
  row: ModuleRow;
  field: WidgetField;
  year: string;
  phases: Reference[];
  timeBlocks: Reference[];
};

export type ModuleCellFieldNames =
  | "credits"
  | "description"
  | "offerings"
  | "phase";

/**
 * We should probably merge this with ProductWidgetItem and use WidgetItem
 **/
export default function ModuleCell({
  row,
  field,
  year,
  phases,
  timeBlocks
}: Readonly<ModuleCellProps>) {
  switch (field.name as ModuleCellFieldNames) {
    case "credits":
      return `${getCredits(row.module?.data.credits)}`;
    case "description":
      return row.structure.description ?? "";
    case "offerings":
      return getPeriodsDisplayName(row.structure.offerings, timeBlocks).join(
        ", "
      );
    case "phase":
      return displayName(row.structure.phase, phases, row.structure.phase);
    default:
      return (
        <CustomItem
          field={field}
          values={row.structure.values}
          year={year}
          type={WidgetType.STRUCTURE}
          hideHeader={false}
          isTitle={false}
        />
      );
  }
}

const ENLARGED_FIELD_NAMES: Set<ModuleCellFieldNames> = new Set([
  "description",
  "offerings"
]);

export function isEnlargedModuleCell(field: WidgetField) {
  return ENLARGED_FIELD_NAMES.has(field.name as ModuleCellFieldNames);
}

export function partitionBySize(
  fields: WidgetField[]
): [WidgetField[], WidgetField[]] {
  return partition(fields, isEnlargedModuleCell);
}
