import { useMemo } from "react";
import { orderBy } from "lodash";
import { getText } from "../../../i18n/LocalizedText";
import { type Widget } from "../../types/Widget";

export function useWidgetSelectOptions(
  widgets?: Widget[],
  entityType?: string
): Widget[] {
  return useMemo(() => {
    if (!widgets) {
      return [];
    }

    const processedWidgets = widgets.map((widget) => {
      const isMatchingEntity = widget.entityType === entityType;
      const baseName = getText(widget.labels, widget.name);
      const widgetName = isMatchingEntity
        ? baseName
        : `(${widget.entityType}) ${baseName}`;

      return {
        ...widget, // Do not mutate the original widget
        name: widgetName,
        matches: isMatchingEntity,
        labels: [] // Clear labels so that the name will be used
      };
    });

    return orderBy(processedWidgets, ["matches", "name"], ["desc", "asc"]);
  }, [widgets, entityType]);
}
