import { AsyncContent } from "@42.nl/ui";
import { type UseQueryResult } from "@tanstack/react-query";
import { Col, Row, Spinner } from "reactstrap";
import { Delayed } from "../Delayed/Delayed";

interface Props<T> {
  /**
   * Render function which takes the `data` from the `useQuery`'s
   * `state` when the promise is fulfilled, and expects a you
   * to render content.
   */
  children: (data: T) => React.ReactNode;

  /**
   * Result from calling `useQuery` from `react-query`.
   */
  state: UseQueryResult<T | undefined>;

  /**
   * An optional callback which gets called when the data has
   * loaded. When `isEmpty` returns `true` the `emptyContent` is
   * rendered.
   */
  isEmpty?: (data?: T) => boolean;

  /**
   * Optionally when `isEmpty` returns `true` what content to render.
   */
  emptyContent?: (data?: T) => React.ReactNode;
}

export default function Loader<T>({
  children,
  state,
  isEmpty,
  emptyContent
}: Readonly<Props<T>>) {
  if (state.isLoading) {
    return (
      <Delayed delay={250}>
        {() => (
          <Row className="p-2">
            <Col xs={12}>
              <Spinner color="warning" />
            </Col>
          </Row>
        )}
      </Delayed>
    );
  }

  return (
    <AsyncContent state={state} isEmpty={isEmpty} emptyContent={emptyContent}>
      {children}
    </AsyncContent>
  );
}
