import { isEmpty } from "lodash";
import type Relation from "../../../types/canonical/Relation";
import { type WidgetType } from "../../types/WidgetType";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { FieldSection } from "./FieldSection";

type Props = {
  field: WidgetField;
  type: WidgetType;
  relations: Relation[] | undefined;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function RelationsItem({
  field,
  type,
  relations,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const persons = getPersons(field.name, relations);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(persons)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <>
          {persons.map((person, index) => (
            <div key={`person-${index}`}>{person}</div>
          ))}
        </>
      )}
    </FieldSection>
  );
}

function getPersons(role: string, relations: Relation[] | undefined) {
  if (!relations) {
    return [];
  }

  return relations
    .filter((relation) => relation.type?.code === role)
    .map((relation) => relation.person?.name)
    .sort();
}
