import { type Product } from "../../types/Product";
import { WidgetType } from "../../widgets/types/WidgetType";
import { type WidgetField } from "../../widgets/WidgetField/WidgetField";
import { WidgetItem } from "../../widgets/WidgetItem/WidgetItem";

type Props = {
  field: WidgetField;
  product?: Product;
};

export default function ProductWidgetItem({ field, product }: Readonly<Props>) {
  if (!product) {
    return null;
  }

  const handleLinkPreventDefault = (e: React.MouseEvent) => {
    if (field.type === "URL" || field.type === "LINKS") {
      if (e.target instanceof HTMLAnchorElement) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
  return (
    <div onClick={handleLinkPreventDefault}>
      <WidgetItem
        key={`field-${field.id}`}
        field={field}
        type={WidgetType.PRIMARY}
        hideHeader={true}
        product={product}
        expanded={false}
        expandable={false}
        isTitle={false}
      />
    </div>
  );
}
