import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { urlBuilder } from "@42.nl/react-url";
import { useSpring, animated } from "@react-spring/web";
import { isEmpty, split } from "lodash";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import { getText } from "../../../../../../i18n/LocalizedText";
import { useMainTabs } from "../../../../../../tabs/context/hooks/useMainTabs";
import type Tab from "../../../../../../tabs/types/Tab";
import { getTabUrl } from "../../../../../../tabs/utils/tabUrlUtils";
import { tabHasFilter } from "../../../../../../tabs/utils/tabUtils";

type MainTabsProps = {
  closeMobileMenu: () => void;
};

export default function MainTabs({ closeMobileMenu }: Readonly<MainTabsProps>) {
  const tabs = useMainTabs();

  return (
    <Nav navbar className="main-nav flex-row">
      {tabs?.map((tab) => (
        <li key={tab.id} className={`${tab.isActiveMain ? "tab-active" : ""}`}>
          <MainTab tab={tab} onClick={closeMobileMenu} />
        </li>
      ))}
    </Nav>
  );
}

type MainTabProps = {
  tab: Tab;
  onClick: () => void;
};

function MainTab({ tab, onClick }: Readonly<MainTabProps>) {
  const baseUrl = getTabUrl(tab) || "";

  if (isEmpty(baseUrl)) {
    return <></>;
  }

  return (
    <EntityTab
      tab={tab}
      active={tab.isActiveMain}
      baseUrl={baseUrl}
      onClick={onClick}
    />
  );
}

type EntityTabProps = {
  tab: Tab;
  active: boolean;
  baseUrl: string;
  onClick: () => void;
};

function EntityTab({
  tab,
  active,
  baseUrl,
  onClick
}: Readonly<EntityTabProps>) {
  const location = useLocation();

  function getUrl() {
    const searchParams = queryString.parse(location.search);
    const year = Array.isArray(searchParams.year)
      ? searchParams.year[0]
      : searchParams.year;
    const params = { ...toQueryParams(tab, year) };

    return urlBuilder({
      url: baseUrl,
      pathParams: params,
      queryParams: params
    });
  }

  const contentRef = useRef<HTMLAnchorElement>(null);
  const [tabSpringStyles, tabSpringAPI] = useSpring(() => ({}));

  const { i18n } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const text = useMemo(() => getText(tab.labels, tab.name), [i18n.language]);

  const [currentTabWidth, setCurrentTabWidth] = useState(0);
  // Set old width before useEffect so we know which width we come from.
  useLayoutEffect(() => {
    const { current: content } = contentRef;
    if (!content) return;
    if (!text) return;

    const { width } = content.getBoundingClientRect();
    setCurrentTabWidth(width);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    const { current: content } = contentRef;
    if (!content) return;
    if (!currentTabWidth) return;

    const { width } = content.getBoundingClientRect();
    tabSpringAPI.start(() => ({
      from: { width: currentTabWidth },
      to: { width }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <NavLink
      to={getUrl()}
      onClick={onClick}
      className={() => `${active ? "active" : ""}`}
      replace
    >
      <span className="main-tab__content-wrapper">
        <animated.span style={tabSpringStyles}>
          <span ref={contentRef}>{text}</span>
        </animated.span>
      </span>
    </NavLink>
  );
}

export function toQueryParams(tab: Tab | undefined, year: string | null) {
  const params = {
    year: year || ""
  };

  if (tab && tabHasFilter(tab)) {
    const [name, value] = split(tab.filter, "=").map((value_) => value_.trim());

    if (!isEmpty(name)) {
      const values = split(value, ",").map((value_) => value_.trim());
      return { [name]: values, ...params };
    }
  }

  return params;
}

export type MainTabParams = {
  mainTab?: string;
};
