import {
  type FlashMessage as FlashMessageShape,
  removeFlashMessage,
  useFlashMessages
} from "@42.nl/react-flash-messages";
import { type Color, FlashMessage } from "@42.nl/ui";

/**
 * FlashMessages is a graphical implementation for https://github.com/42BV/react-flash-messages.
 */
export default function FlashMessages() {
  const flashMessages = useFlashMessages();

  if (flashMessages.length === 0) {
    return <></>;
  }

  /**
   * Remove FlashMessage upon clicking.
   *
   * @param flashMessage
   */
  function onFlashMessageClick(flashMessage: FlashMessageShape<any>) {
    flashMessage.onClick();
    removeFlashMessage(flashMessage);
  }

  return (
    <div className="flash-messages">
      {flashMessages.map((flashMessage: FlashMessageShape<any>) => (
        <FlashMessage
          key={flashMessage.id}
          color={messageColorByType(flashMessage.type)}
          onClose={() => onFlashMessageClick(flashMessage)}
        >
          {flashMessage.text}
        </FlashMessage>
      ))}
    </div>
  );
}

/**
 * Assign bootstrap colors to specific FlashMessage types.
 *
 * @param {color} type
 * @returns {string}
 */
function messageColorByType(type: string): Color {
  switch (type) {
    case "SUCCESS":
      return "success";
    case "WARNING":
      return "warning";
    case "ERROR":
    case "APOCALYPSE":
      return "danger";
    case "INFO":
    default:
      return "info";
  }
}
