import { useMemo } from "react";
import { uniqueId } from "lodash";
import { QuickviewItem } from "../../../components/Quickview/components/QuickviewItem";
import { QuickviewTitle } from "../../../components/Quickview/components/QuickviewTitle";
import { getText } from "../../../i18n/LocalizedText";
import type Reference from "../../../references/Reference";
import { displayName } from "../../../references/Reference";
import { useReferences } from "../../../references/useReferences";
import type Appraisal from "../../../types/canonical/Appraisal";
import { getAppraisals } from "../../../types/canonical/Appraisal";
import { type Widget } from "../../types/Widget";
import {
  getVisibleSortedWidgetFields,
  getWidgetFieldValue
} from "../../utils/WidgetUtils";
import { type WidgetField } from "../../WidgetField/WidgetField";
import { WidgetTitle } from "../components/WidgetTitle";
import { type WidgetRenderVariantProps } from "../types/WidgetRenderVariantProps";

export function AppraisalsWidget({
  widget,
  product
}: Readonly<WidgetRenderVariantProps>) {
  const appraisals = getAppraisals(product?.data?.assessments, widget.filter);

  return (
    <>
      <WidgetTitle widget={widget} />
      {appraisals.map((appraisal, index) => (
        <AppraisalQuickview
          key={`appraisal-${appraisal.id}-${index}`}
          widget={widget}
          appraisal={appraisal}
          year={product.data.year.id}
        />
      ))}
    </>
  );
}

interface AppraisalQuickviewProps {
  widget: Widget;
  appraisal: Appraisal;
  year: string;
}

function AppraisalQuickview({
  widget,
  appraisal,
  year
}: Readonly<AppraisalQuickviewProps>) {
  const children = getAppraisals(
    appraisal.assessment?.assessments,
    widget.filter
  );
  const quickviewTitle = useMemo(
    () => getText(appraisal.assessment?.names),
    [appraisal.assessment?.names]
  );

  return (
    <section className="quickview quickview-widget product-panel d-flex flex-column cursor--default">
      <div className="quickview__main d-flex flex-row align-items-center">
        <div className="d-flex flex-column flex-grow-1">
          <div>
            <QuickviewTitle>{quickviewTitle}</QuickviewTitle>
          </div>
          <div className="quickview__main-attributes my-2 flex-row flex-wrap">
            {getVisibleSortedWidgetFields(widget).map((field) => (
              <AppraisalItem
                key={field.id}
                field={field}
                appraisal={appraisal}
                year={year}
              />
            ))}
          </div>
        </div>
      </div>

      {appraisal.description && (
        <div className="quickview__extra">
          <div className="quickview__extra__summary">
            {appraisal.description}
          </div>
        </div>
      )}

      {children.length > 0 && (
        <div className="pt-2">
          {children.map((assessment) => (
            <div key={uniqueId("aq-nested-")} className="quickview__extra">
              <AppraisalQuickview
                widget={widget}
                appraisal={assessment}
                year={year}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

type ItemProps = {
  field: WidgetField;
  appraisal: Appraisal;
  year: string;
};

function AppraisalItem({ field, appraisal, year }: Readonly<ItemProps>) {
  const { references } = useReferences(field.referenceType, year);
  const value = getAppraisalValue(field, appraisal, references);

  if (!value && field.hideEmpty) {
    return <div></div>;
  }

  return (
    <QuickviewItem
      label={getText(field.labels)}
      tooltip={getText(field.tooltips, undefined)}
      value={value || "N/A"}
    />
  );
}

function getAppraisalValue(
  field: WidgetField,
  appraisal: Appraisal,
  references: Reference[]
) {
  switch (field.name) {
    case "criteria":
      return displayName(appraisal.criteria, references);
    case "duration":
      return appraisal.assessment?.duration;
    case "evaluation":
      return displayName(appraisal.assessment?.evaluation, references);
    case "type":
      return displayName(appraisal.assessment?.type, references);
    case "weighting":
      return appraisal.weighting;
  }

  let value = getWidgetFieldValue(field, appraisal.values || [], references);
  if (!value) {
    value = getWidgetFieldValue(
      field,
      appraisal.assessment?.values || [],
      references
    );
  }
  return value;
}
