import { useEffect, useState, useCallback } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { isEmpty, defaultTo } from "lodash";

interface ColorLabelProps {
  label: string;
  tooltipLabel?: string;
  hideLabel?: boolean;
  color?: string;
  textColor?: string;
  squared?: boolean;
  small?: boolean;
}

const ColorLabel = ({
  label,
  tooltipLabel,
  hideLabel = false,
  color = "var(--colorTableOddBg)",
  textColor = "var(--colorTextBase)",
  squared = false,
  small = false
}: ColorLabelProps) => {
  const [trimmedLabel, setTrimmedLabel] = useState("");
  const [tooltip, setTooltip] = useState("");
  const [useSmallFont, setUseSmallFont] = useState(false);

  const setLabel = useCallback(() => {
    const finalTrimmedLabel = squared ? getTrimmedLabel(label) : label;
    setTrimmedLabel(finalTrimmedLabel);
    setTooltip(defaultTo(tooltipLabel, label));
    setUseSmallFont(small || finalTrimmedLabel.length >= 3);
  }, [label, tooltipLabel, squared, small]);

  useEffect(() => {
    setLabel();
  }, [setLabel]);

  const getTrimmedLabel = (labelToCheck: string): string =>
    isEmpty(labelToCheck) ? "" : labelToCheck.trim().substring(0, 4);

  return (
    <Tooltip title={tooltip} placement="top" overlayStyle={{ zIndex: 1000 }}>
      <div
        className={classNames(
          "color-label__container",
          "sg-rounded",
          "sg-p-0",
          "sg-flex",
          "sg-items-center",
          "sg-justify-center",
          "sg-h-full",
          "sg-w-full",
          {
            squared,
            small
          }
        )}
        style={{
          color: textColor,
          backgroundColor: color
        }}
        aria-label={tooltip}
      >
        {!hideLabel && (
          <div
            className={classNames("color-label-value", {
              "font-size--base--i": useSmallFont
            })}
            aria-label={tooltip}
          >
            <span>{trimmedLabel}</span>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ColorLabel;
