import { useMemo } from "react";
import { type StrictColumnsType } from "../../../../../components/Table/types/StrictColumnsType";
import { useParseFilter } from "../../../../../filters/hooks/useParseFilter";
import type Subject from "../../../../../types/canonical/Subject";
import { type Product } from "../../../../../types/Product";
import { type Widget } from "../../../../types/Widget";
import MatrixWidgetTableSubjectCell from "../../MatrixWidgetTable/MatrixWidgetTableSubjectCell";
import MatrixWidgetTableSubjectHeaderCell from "../../MatrixWidgetTable/MatrixWidgetTableSubjectHeaderCell";
import { type ProductTreeNode } from "../../ProductTree/ProductTreeStore/types/ProductTreeNode";
import { categorizeSubjectsBySubType } from "../subjectUtils";
import useProductSubjects from "./useProductSubjects.hook";

export function useCategorizedSubjectColumns(
  product: Product,
  widget: Widget
): StrictColumnsType<ProductTreeNode> {
  const widgetFilter = useParseFilter(widget?.filter);
  const productSubjects = useProductSubjects({ widgetFilter, product });

  return useMemo(() => {
    const subjectsByCategory = categorizeSubjectsBySubType(productSubjects);
    const entries: [string, Subject[]][] = Object.entries(subjectsByCategory);

    const columns: StrictColumnsType<ProductTreeNode> = entries.map(
      ([category, subjects]): StrictColumnsType<ProductTreeNode>[number] => {
        const categoryKey = `category-${category}`;

        return {
          key: categoryKey,
          title: category,
          children: subjects.map((subject) => {
            const subjectKey = `${categoryKey}-subject-${subject.reference.id}`;

            return {
              key: subjectKey,
              title: () => (
                <MatrixWidgetTableSubjectHeaderCell subject={subject} />
              ),
              onHeaderCell: () => ({
                className:
                  "matrix-widget__cell matrix-widget__cell--hover matrix-widget__cell--subject matrix-widget__cell--subject--title"
              }),
              onCell: () => ({
                className:
                  "matrix-widget__cell matrix-widget__cell--hover matrix-widget__cell--subject"
              }),
              width: 47,
              render: (node: ProductTreeNode) => (
                <MatrixWidgetTableSubjectCell
                  widgetFilter={widgetFilter}
                  subject={subject}
                  product={node.product}
                />
              )
            };
          })
        };
      }
    );

    return columns;
  }, [productSubjects, widgetFilter]);
}
