import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { getText } from "../i18n/LocalizedText";
import { type Product } from "../types/Product";
import type Tab from "./types/Tab";

type Props = {
  subTabs: Tab[];
  product?: Product;
  activeTabName?: string;
  toggle: (name: string, value: string | number) => void;
};

export default function SubTabsNav({
  subTabs,
  product,
  activeTabName,
  toggle
}: Readonly<Props>) {
  if (!(subTabs.length > 1)) {
    return <div className="mb-3"></div>;
  }

  return (
    <Nav tabs className="mt-4 mb-2">
      {product &&
        subTabs?.map((subTab) => (
          <NavItem id={subTab.id.toString()} key={subTab.id}>
            <NavLink
              className={classnames({
                active: activeTabName === subTab.name
              })}
              onClick={() => toggle("tab", subTab.name)}
            >
              {getText(subTab.labels, subTab.name)}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
}
