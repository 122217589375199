import { useTranslation } from "react-i18next";
import { QuickviewItem } from "../../components/Quickview/components/QuickviewItem";
import { ProductQuickview } from "../../components/Quickview/Product/ProductQuickview";
import { ReferenceType } from "../../references/Reference";
import { useReferences } from "../../references/useReferences";
import { getCredits } from "../../types/canonical/Credits";
import { getPeriodsDisplayName } from "../../types/canonical/Offering";
import { type Product } from "../../types/Product";
import { type Widget } from "../../widgets/types/Widget";
import { moduleDetailUrl } from "../ModuleDetail/ModuleDetail";

interface Props {
  module: Product;
  widgets: Widget[];
}

export default function ModuleListItem({ module, widgets }: Readonly<Props>) {
  const { t, ready } = useTranslation(["module"], {
    useSuspense: false // Prevent re-renders caused by combining useQuery and useTranslation
  });

  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    module.data.year.id
  );

  if (!ready) {
    return <></>;
  }

  const periods = getPeriodsDisplayName(module.data.offerings, timeBlocks).join(
    ", "
  );

  return (
    <ProductQuickview
      key={module.id}
      product={module}
      widgets={widgets}
      seeMoreText={t("module:SEE_MORE")}
      linkToDetail={moduleDetailUrl({ ...module.data })}
    >
      <QuickviewItem
        label={t("module:SHORT.CREDITS")}
        value={getCredits(module.data.credits)}
      />
      <QuickviewItem
        label={t("module:COLUMNS.MODULE.PERIOD")}
        value={periods}
      />
      <QuickviewItem
        label={t("module:COLUMNS.YEAR")}
        value={module.data.year.id}
      />
    </ProductQuickview>
  );
}
