import { createContext, type PropsWithChildren, useMemo } from "react";
import { tabApi } from "../service/tabApi";
import type Tab from "../types/Tab";

export function TabsProvider({ children }: Readonly<PropsWithChildren>) {
  const { data: sortedTabs, isLoading } = tabApi.list.useQuery();

  const value = useMemo(
    () => ({ sortedTabs, isLoading }),
    [sortedTabs, isLoading]
  );

  if (isLoading) {
    return <></>;
  }

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
}

export const TabsContext = createContext<TabsContextType | undefined>(
  undefined
);

interface TabsContextType {
  sortedTabs: Tab[] | undefined;
  isLoading: boolean;
}
