import { type Key } from "react";
import { create } from "zustand";
import { type ProductTreeBulkToggleMode } from "../../ProductTreeActions/ProductTreeBulkToggle/types/ProductTreeBulkToggleMode";
import productTreeBulkToggleReducer from "../../ProductTreeActions/ProductTreeBulkToggle/utils/productTreeBulkToggleReducer";
import { type ProductTree } from "../types/ProductTree";
import { type ProductTreeStoreState } from "../types/ProductTreeStore";

export const useProductTreeStore = create<ProductTreeStoreState>()(
  (set, get) => ({
    currentProductUid: null,

    productTree: [],

    setProductTree: (productTree: ProductTree, productUid: string) =>
      set({ productTree, currentProductUid: productUid }),

    updateProductTreeNode: (compositeUid, updates) => {
      const updateTreeNode = (nodes: ProductTree): ProductTree =>
        nodes.map((node) => {
          if (node.key === compositeUid) {
            return { ...node, ...updates };
          }
          if (node.children) {
            return { ...node, children: updateTreeNode(node.children) };
          }
          return node;
        });

      set((state) => ({ productTree: updateTreeNode(state.productTree) }));
    },

    expandedRowKeys: [],

    updateExpandedRowKeys: (expandedKeys: Key[]) => {
      set({ expandedRowKeys: expandedKeys });
    },

    onBulkToggleAction: async (
      action: ProductTreeBulkToggleMode,
      onNodeToggle: (key: Key) => Promise<void>
    ) => {
      const { productTree, expandedRowKeys } = get();

      const updatedKeys = productTreeBulkToggleReducer(
        productTree,
        expandedRowKeys,
        action
      );

      // Expand first, for a smoother UI
      set({ expandedRowKeys: updatedKeys });

      // Then process each key sequentially to prevent race conditions between parent and child updates
      for (const key of updatedKeys) {
        await onNodeToggle(key);
      }
    }
  })
);
