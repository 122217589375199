import _ from "lodash";
import type Activity from "./Activity";
import type Assessment from "./Assessment";
import type Value from "./Value";

export default class Appraisal {
  assessment?: Assessment;
  code?: string;
  choiceType?: string;
  choiceValue?: number;
  criteria?: string;
  description?: string;
  id?: string;
  required?: boolean;
  sequence?: number;
  weighting?: number;
  values?: Value[];
}

export function getAppraisals(
  appraisals: Appraisal[] | undefined,
  category: string | undefined
) {
  if (!appraisals) {
    return [];
  }

  return _(appraisals)
    .filter((appraisal) => {
      if (!category) {
        return true;
      }

      return appraisal.assessment?.category === category;
    })
    .map((appraisal) => {
      if (appraisal.assessment?.activities) {
        appraisal.assessment.activities = getActivities(
          appraisal.assessment.activities
        );
      }
      return appraisal;
    })
    .value();
}

function getActivities(activities: Activity[]) {
  const ACTIVITY_SORT_PROPS: Array<keyof Activity> = ["code"];

  return _(activities).sortBy(ACTIVITY_SORT_PROPS).value();
}
