import { isEmpty } from "lodash";
import AcademicSession from "../types/AcademicSession";
import { getYearService } from "../years/YearService";

class PeriodService {
  private periods: AcademicSession[] = [];

  constructor() {
    this.loadPeriods = this.loadPeriods.bind(this);
  }

  async loadPeriods() {
    this.periods = await AcademicSession.page({
      sort: ["startDate", "endDate", "code"],
      size: 9999
    }).then((page) => page.content);
  }

  getPeriods(yearExternalId?: string): AcademicSession[] {
    const currentYear = getYearService().getYear(yearExternalId);
    return this.periods.filter(
      (period) => period.year.externalId === currentYear?.externalId
    );
  }
}

const instance = new PeriodService();

export function getPeriodService(): PeriodService {
  return instance;
}

export function getPeriodSequence(
  periods: AcademicSession[],
  externalId: string | undefined
) {
  if (!isEmpty(externalId)) {
    return periods.findIndex((period) => period.externalId === externalId);
  }
  return -1;
}
