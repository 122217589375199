import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { drop, take } from "lodash";
import { getText } from "../../../../../i18n/LocalizedText";
import type Subject from "../../../../../types/canonical/Subject";
import ColorLabel from "./ColorLabel";

interface SubjectValuesProps {
  subjects?: Subject[];
}

const SubjectValues = ({ subjects = [] }: SubjectValuesProps) => {
  const [shownValues, setShownValues] = useState<Subject[]>([]);
  const [plusLabel, setPlusLabel] = useState<string | null>(null);
  const [plusTooltipLabel, setPlusTooltipLabel] = useState("");
  const [shouldBeSmall, setShouldBeSmall] = useState(false);

  useEffect(() => {
    const processedSubjects: Subject[] = subjects
      ?.filter(Boolean)
      .map((subject, index) => ({
        ...subject,
        generatedUniqueKey: `subject-value-${subject.reference.code}-${subject.scale?.code}-${index}`
      }));

    const amountOfValues = processedSubjects.length;

    if (amountOfValues > 4) {
      const shown: Subject[] = take(processedSubjects, 3); // Reserve space for the "plus" label
      const hidden: Subject[] = drop(processedSubjects, 3);
      setShownValues(shown);
      setPlusLabel(`+${hidden.length}`);
      setPlusTooltipLabel(
        hidden
          .map(({ scale }) => {
            return getText(scale?.names, scale?.code);
          })
          .filter(Boolean)
          .join(", ")
      );
    } else {
      setShownValues(processedSubjects);
      setPlusLabel(null);
      setPlusTooltipLabel("");
    }

    setShouldBeSmall(amountOfValues > 1);
  }, [subjects]);

  return (
    <div
      className={classNames(
        "sg-flex sg-flex-wrap sg-gap-1 sg-justify-center sg-items-center sg-w-full sg-h-full sg-p-1 sg-font-semibold",
        {
          "sg-grid sg-grid-cols-2": shouldBeSmall,
          "sg-flex-col": !shouldBeSmall
        }
      )}
    >
      {shownValues.map((value) => {
        if (!value.scale) {
          return null;
        }
        return (
          <div
            key={value.generatedUniqueKey}
            className={classNames("subject-value sg-h-full sg-w-full", {
              "sg-w-1/2 !sg-h-[17px]": shouldBeSmall
            })}
          >
            <ColorLabel
              label={value.scale.code || "~"}
              textColor={undefined} // Note no colors are currently available in the API
              color={undefined} // Note no colors are currently available in the API
              squared
              small={shouldBeSmall}
              tooltipLabel={getText(value.scale.names, value.scale.code)}
            />
          </div>
        );
      })}

      {plusLabel && (
        <div className="sg-w-full sg-text-center">
          <Tooltip title={plusTooltipLabel} placement="top">
            <div className="color-label--small__plus">
              <span className="font-sm-strong">{plusLabel}</span>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SubjectValues;
