import { useEffect, useMemo } from "react";
import { type Product } from "../../../../../../types/Product";
import { getProductGroups } from "../../../Subjects/subjectUtils";
import buildTopLevelProductTree from "../utils/buildTopLevelProductTree";
import { useProductTreeData } from "./useProductTreeData.hook";
import { useProductTreeStore } from "./useProductTreeStore.hook";

export default function useProductTree(product: Product, productUid: string) {
  const {
    currentProductUid,
    productTree,
    setProductTree,
    expandedRowKeys,
    updateExpandedRowKeys
  } = useProductTreeStore();
  const { fetchGroupModules } = useProductTreeData(product.data.year.id);

  const nodes = useMemo(() => {
    const initialGroups = getProductGroups(product);
    return buildTopLevelProductTree(
      initialGroups,
      product,
      product.productType,
      fetchGroupModules
    );
  }, [fetchGroupModules, product]);

  useEffect(() => {
    if (productUid !== currentProductUid) {
      nodes.then((initialProductTree) =>
        setProductTree(initialProductTree, productUid)
      );
    }
  }, [currentProductUid, nodes, productUid, setProductTree]);

  return {
    productTree,
    currentProductUid,
    expandedRowKeys,
    updateExpandedRowKeys
  };
}
